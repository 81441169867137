@use '../../../sass/' as *;

#billDetailsCommercail {
  width: 100%;
  @media (max-width: 1300px) {
    width: 69rem;
  }
  .details-bill-header {
    @include header-page-add-see;
  }
  #title-bill {
    width: 60%;
    margin: 2rem auto;
    text-align: center;
    h1 {
      color: $black-0;
    }
  }
  .container {
    .credentials {
      margin-top: 2rem;
      display: flex;
      gap: 3rem;
      width: 100%;
      .left {
        display: flex;
        flex-direction: column;
        border: $primary-border;
        border-radius: $border-radius;
        padding: 1rem;
        width: 40%;
        .left-el {
          display: flex;
          flex-direction: column;
          margin-bottom: 0.5rem;
          label {
            color: $green-1;
            display: inline-block;
            font-size: 18px;
            font-weight: 600;
            letter-spacing: 1px;
          }
          span {
            color: $black-4;
          }
        }
      }
    }
    .devis {
      .devis-head {
        margin-top: 3rem;
        display: flex;
        flex-direction: column;
        h1 {
          color: $black-0;
        }
        span {
          display: block;
          width: 80px;
          height: 4px;
          background-color: $green-1;
        }
      }
      .devis-table {
        width: 100%;
        margin-top: 4rem;
        .offres {
          width: 100%;
          border-top: 1px solid $gray-1-0;
          border-bottom: 1px solid $gray-1-0;

          #titres {
            width: 100%;
            height: 3rem;
            align-items: center;
            font-weight: 600;
            display: grid;
            grid-template-columns: 15% 30% 10% 15% 10% 15% 10%;
            letter-spacing: 0.5px;
            td {
              color: $black-0;
              letter-spacing: 0.5px;
            }
          }
          tbody {
            .elements {
              width: 100%;
              height: 4rem;
              align-items: center;
              display: grid;
              grid-template-columns: 15% 30% 10% 15% 10% 15% 10%;
              letter-spacing: 0.5px;
              border-top: 1px solid $gray-1-0;
            }
          }
        }
        .total-devis {
          display: flex;
          justify-content: flex-end;
          margin-top: 4rem;
          margin-right: 8%;
          div {
            gap: 5.5rem;
            display: flex;
            align-items: center;

            h1,
            span {
              font-size: 1.2rem;
              color: $black-4;
              font-weight: 600;
            }
          }
        }
      }
      .billTable {
        margin-top: 10rem;
        width: 100%;
        #bt-principal {
          width: 100%;
          border: 3px solid $gray-1-0;
          #firstBigRow {
            width: 100%;
            display: grid;
            grid-template-columns: 44% 44% 12%;
            > td:first-child {
              border-right: 3px solid $gray-1-0;
            }
            #bt-nested-1 {
              width: 100%;
              #bt-head-1 {
                display: grid;
                grid-template-columns: repeat(4, 25%);
                align-items: center;
                height: 3rem;
                td {
                  color: $black-0;
                  font-weight: 600;
                  letter-spacing: 0.5px;
                  padding-left: 0.2rem;
                }
              }
              .bt-tl-1 {
                height: 3rem;
                display: grid;
                grid-template-columns: repeat(4, 25%);
                align-content: center;
                border-top: 3px solid $gray-1-0;
                td {
                  height: 100%;
                  padding-left: 0.2rem;
                }
              }
            }
            #bt-nested-2 {
              border-right: 3px solid $gray-1-0;
              width: 100%;
              #bt-head-2 {
                display: grid;
                grid-template-columns: repeat(4, 25%);
                align-items: center;
                height: 3rem;
                td {
                  color: $black-0;
                  font-weight: 600;
                  letter-spacing: 0.5px;
                  padding-left: 0.5rem;
                }
              }
              .bt-tl-2 {
                height: 3rem;
                display: grid;
                grid-template-columns: repeat(4, 25%);
                align-content: center;

                td {
                  height: 100%;
                  padding-left: 0.5rem;
                }
              }
              #bt-tl-2 {
                border-top: 3px solid $gray-1-0;
              }
            }
            #bt-nested-3 {
              width: 100%;
              #bt-head-3 {
                width: 100%;
                height: 3rem;
                text-align: center;

                td {
                  color: $black-0;
                  font-weight: 600;
                  letter-spacing: 0.5px;
                }
              }
              .bt-tl-3 {
                width: 100%;
                height: 3rem;
                align-content: center;

                td {
                  height: 100%;
                  text-align: right;
                  padding-right: 1rem;
                  border-top: 3px solid $gray-1-0;
                  font-weight: 600;
                  letter-spacing: 0.5px;
                }
              }
            }
          }
          #secondBigRow {
            width: 100%;
            display: grid;
            grid-template-columns: 44% 56%;
            #bt-nested-4 {
              width: 100%;
              border-top: 3px solid $gray-1-0;
              border-right: 3px solid $gray-1-0;
              .bt-head-4 {
                height: 3rem;
                display: grid;
                grid-template-columns: repeat(4, 25%);
                align-content: center;
                td {
                  color: $black-0;
                  font-weight: 600;
                  letter-spacing: 0.5px;
                  padding-left: 0.2rem;
                }
              }
            }
            #bt-nested-5 {
              width: 100%;
              border-top: 3px solid $gray-1-0;
              #bt-head-5 {
                height: 3rem;
                display: grid;
                grid-template-columns: 35% 65%;
                align-content: center;
                td {
                  padding-left: 0.5rem;
                  font-weight: 600;
                  color: $black-0;
                  letter-spacing: 0.5px;
                }
              }
              .bt-tl-5 {
                height: 3rem;
                display: grid;
                grid-template-columns: 35% 65%;
                align-content: center;
                td {
                  padding-left: 0.5rem;
                }
              }
            }
          }
        }
        .billEnd {
          display: flex;
          flex-direction: column;
          margin-top: 3rem;
          gap: 1rem;
          h2 {
            color: $black-0;
            letter-spacing: 0.5px;
            font-size: 1.3rem;
          }
          span {
            color: $black-4;
          }
        }
      }
    }
    .footer {
      display: flex;
      gap: 5rem;
      border-top: 1px solid $gray-1-0;
      margin-top: 4rem;
      padding-bottom: 3rem;
      padding-top: 3rem;
      .left {
        ul {
          display: flex;
          flex-direction: column;
          li {
            display: inline-block;
            font-size: 16px;
            letter-spacing: 1px;
            color: $black-6;
            font-weight: 600;
          }
        }
      }
    }
  }
}

.bill-pdf-commmercial {
  padding: 40px;
  .devis {
    width: 14.8125rem;
    height: 5.3125rem;
    border: 0.404px solid #cbd5e1;
    background: #f6f6f6;
    padding: 0.75rem;
    h4 {
      color: #316161;
      font-weight: 700;
      line-height: normal;
    }
    .info {
      display: flex;
      gap: 4rem;
      label,
      span {
        font-size: 0.5rem;
        color: #316161;
      }
      div {
        display: flex;
        flex-direction: column;
      }
    }
  }
  .general-info {
    margin-top: 1.75rem;
    display: flex;
    gap: 6.7rem;
    .left {
      width: 13rem;
      .infos-societe {
        font-size: 0.875rem;
        color: #316161;
        margin-bottom: 1rem;
      }
      .address {
        display: flex;
        flex-direction: column;
        gap: 0.75rem;
        span {
          font-size: 0.625rem;
        }
      }
    }
    .right {
      h3 {
        color: #316161;
        font-size: 0.875rem;
        font-weight: 500;
        line-height: 1rem;
        margin-bottom: 1rem;
      }
      p {
        color: #0f172a;
        font-size: 0.625rem;
        font-weight: 300;
      }
      span {
        color: #0f172a;
        font-size: 0.625rem;
        font-weight: 300;
      }
    }
  }

  .devis-table {
    width: 100%;
    margin-top: 3rem;

    .offres {
      width: 100%;
      #titres {
        width: 100%;
        height: 2.5rem;
        align-items: center;
        font-weight: 600;
        display: grid;
        font-size: 0.6rem;
        grid-template-columns: 15% 30% 10% 15% 10% 15% 10%;
        letter-spacing: 0.5px;
        border: 0.404px solid #cbd5e1;
        background: #f6f6f6;
        margin-top: 3.5rem;
      }
      tbody {
        .elements {
          width: 100%;
          height: 3.5rem;
          align-items: center;
          display: grid;
          grid-template-columns: 15% 30% 10% 15% 10% 15% 10%;
          letter-spacing: 0.5px;
          border-bottom: 1px solid $gray-1-0;

          td {
            font-size: 0.6rem;
            color: $black-2;
          }
        }
      }
      tr {
        padding: 0.6rem;
      }
    }
  }
  .billTable {
    margin-top: 2.8rem;
    width: 100%;
    #bt-principal {
      width: 100%;
      border: 3px solid $gray-1-0;
      #firstBigRow {
        width: 100%;
        display: grid;
        grid-template-columns: 44% 44% 12%;
        > td:first-child {
          border-right: 3px solid $gray-1-0;
        }
        #bt-nested-1 {
          width: 100%;
          #bt-head-1 {
            display: grid;
            grid-template-columns: repeat(4, 25%);
            align-items: center;
            height: 3rem;
            td {
              font-size: 0.4rem;
              color: $black-0;
              font-weight: 600;
              letter-spacing: 0.5px;
              padding-left: 0.2rem;
            }
          }
          .bt-tl-1 {
            height: 3rem;
            display: grid;
            grid-template-columns: repeat(4, 25%);
            align-content: center;
            border-top: 3px solid $gray-1-0;
            td {
              font-size: 0.4rem;
              height: 100%;
              padding-left: 0.2rem;
            }
          }
        }
        #bt-nested-2 {
          border-right: 3px solid $gray-1-0;
          width: 100%;
          #bt-head-2 {
            display: grid;
            grid-template-columns: repeat(4, 25%);
            align-items: center;
            height: 3rem;
            td {
              font-size: 0.4rem;
              color: $black-0;
              font-weight: 600;
              letter-spacing: 0.5px;
              padding-left: 0.5rem;
            }
          }
          .bt-tl-2 {
            height: 3rem;
            display: grid;
            grid-template-columns: repeat(4, 25%);
            align-content: center;

            td {
              font-size: 0.4rem;
              height: 100%;
              padding-left: 0.5rem;
            }
          }
          #bt-tl-2 {
            border-top: 3px solid $gray-1-0;
          }
        }
        #bt-nested-3 {
          width: 100%;
          #bt-head-3 {
            width: 100%;
            height: 3rem;
            text-align: center;

            td {
              font-size: 0.4rem;
              color: $black-0;
              font-weight: 600;
              letter-spacing: 0.5px;
            }
          }
          .bt-tl-3 {
            width: 100%;
            height: 3rem;
            align-content: center;

            td {
              font-size: 0.4rem;
              height: 100%;
              text-align: right;
              padding-right: 1rem;
              border-top: 3px solid $gray-1-0;
              font-weight: 600;
              letter-spacing: 0.5px;
            }
          }
        }
      }
      #secondBigRow {
        width: 100%;
        display: grid;
        grid-template-columns: 44% 56%;
        #bt-nested-4 {
          width: 100%;
          border-top: 3px solid $gray-1-0;
          border-right: 3px solid $gray-1-0;
          .bt-head-4 {
            height: 3rem;
            display: grid;
            grid-template-columns: repeat(4, 25%);
            align-content: center;
            td {
              font-size: 0.4rem;
              color: $black-0;
              font-weight: 700;
              letter-spacing: 0.5px;
              padding-left: 0.2rem;
            }
          }
        }
        #bt-nested-5 {
          width: 100%;
          border-top: 3px solid $gray-1-0;
          #bt-head-5 {
            height: 3rem;
            display: grid;
            grid-template-columns: 50% 50%;
            align-content: center;
            td {
              font-size: 0.6rem;
              padding-left: 0.5rem;
              font-weight: 600;
              color: $black-0;
              letter-spacing: 0.5px;
            }
          }
          .bt-tl-5 {
            height: 3rem;
            display: grid;
            grid-template-columns: 35% 65%;
            align-content: center;
            td {
              font-size: 0.6rem;
              padding-left: 0.5rem;
            }
          }
        }
      }
    }
    .billEnd {
      display: flex;
      flex-direction: column;
      padding-top: 3rem;
      gap: 1rem;
      h2 {
        color: $black-0;
        letter-spacing: 0.5px;
        font-size: 1.3rem;
      }
      span {
        color: $black-4;
      }
    }
  }

  .footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    gap: 6rem;
    margin-top: 2.5rem;
    background-color: $white;
    padding: 1rem 40px;
    .left {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      li {
        font-size: 0.5rem;
        font-weight: 500;
        color: $black-6;
      }
    }
  }
}
