@use '../utilities/' as *;

.nav-search {

	@include flexcenter;
		background-color: $white !important;
		height: 3rem;
		border: 1px solid $white-2;
		border-radius: $border-radius;
		box-shadow: 0px 0px 5px 1px $gray-1-0;
		width: 20rem;
        input{
            background-color: inherit;
            font-size: $font-size - 0.2rem;
			height: 100%;
            caret-color: $gray-2;
			width: 70%;
        }
        
		
	.searchicon {
        margin-left: .5rem;
		width: 16px;
		height: 16px;
		cursor: pointer;
		color: $gray-1;
	}
}
