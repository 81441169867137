// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

// ------------------- colors ---------------------
$white: #FFFFFF;
$white-1: #F6F6F6;
$white-2:#f4f6f8;
$green-1: #316161;
$green-2: #00a389;
$green-3: #14a36d;
$green-3-0: #D1FAE5;
$green-3-1: #10B981;
$green-4: #83AFB0;
$green-5: #D1FAE5;
$orange-1: #F59E0B;
$orange-2: #FEF3C7;
$orange-3: #FEE2E2;
$color-tertiary: #CBECE7;
$warning: #EE550E;
$danger: #EF4444;
$blue-1: #20384D;
$blue-2: #CBD5E1;
$black-0: #000000;
$black-2:#252525;
$black-2-0:#454545;
$black-3: #777777;
$black-4: #5B5B5B;
$black-5: #1E293B;
$black-6: #0F172A;
$black-7: #1D1D1D ;
$gray-0: #D0D0D0;
$gray-1:#94A3B8;
$gray-1-0:#E9EAEC;
$gray-1-1:#E2E8F0;
$gray-2:#64748B;
$gray-3:#475569;
$gray-4:#334155;
$gray-4-0:#CBD2DA;
$gray-4-1:#9da5b0;
$blue-sky:#B6E0E0;


// ------------------- others ---------------------
$font-family: 'Mukta', sans-serif !default;
$border-radius: 2px !default;
$input-border: 1px solid $gray-1;
$primary-border: 1px solid $gray-1-1;
$logo-color: $white-1;
$font-size: 1.2rem;
$box-shadow: 0px 0px 5px 1px $gray-1-0;
$border-radius: 4px;

$breakpoints: (
  'small': 320px,
  'medium': 768px,
  'large': 1024px,
) !default;
