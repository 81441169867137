@use '../../../sass/' as *;

.modal-delete {
  width: 100dvw;
  height: 100%;
  position: fixed;
  z-index: 9999;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.15s ease-out;
  background-color: rgba(0, 0, 0, 0.75);
  .delete-confirmation-modal {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0px 18px 18px rgba(146, 145, 145, 0.1);
    padding: 20px;
    max-width: 400px;
    margin: auto;
    text-align: center;

    h1 {
      font-size: 1.5rem;
      margin-bottom: 10px;
    }

    p {
      margin-bottom: 20px;
    }

    .modal-btns {
      display: flex;
      justify-content: space-between;

      button {
        padding: 10px 20px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
      }

      .button-nature-inactive {
        background-color: #ccc;
        color: #fff;
      }

      .button-primary {
        background-color: $green-1;
        color: #fff;
      }

    }
  }
}
