@use '../../../sass/' as *;

#deliveryDetails {
  width: 100%;
  @media (max-width: 1300px) {
    width: 69rem;
  }
  .details-delivery-header {
    @include header-page-add-see;
  }
  #title-delivery {
    width: 60%;
    margin: 2rem auto;
    text-align: center;
    h1 {
      color: $black-0;
    }
  }
  .container {
    .credentials {
      margin-top: 2rem;
      display: flex;
      gap: 3rem;
      width: 100%;
      .left {
        display: flex;
        flex-direction: column;
        border: $primary-border;
        border-radius: $border-radius;
        padding: 1rem;
        width: 40%;
        .left-el {
          display: flex;
          flex-direction: column;
          margin-bottom: 0.5rem;
          label {
            color: $green-1;
            display: inline-block;
            font-size: 18px;
            font-weight: 600;
            letter-spacing: 1px;
          }
          span {
            color: $black-4;
          }
        }
      }
    }
    .devis {
      .devis-head {
        margin-top: 3rem;
        display: flex;
        flex-direction: column;
        h1 {
          color: $black-0;
        }
        span {
          display: block;
          width: 80px;
          height: 4px;
          background-color: $green-1;
        }
      }
      .devis-table {
        width: 100%;
        margin-top: 4rem;
        .offres {
          width: 100%;
          border-top: 1px solid $gray-1-0;
          border-bottom: 1px solid $gray-1-0;

          #titres {
            width: 100%;
            height: 3rem;
            align-items: center;
            font-weight: 600;
            display: grid;
            grid-template-columns: 20% 20% 45% 15%;
            letter-spacing: 0.5px;
            td {
              color: $black-0;
              letter-spacing: 0.5px;
            }
          }
          tbody {
            .elements {
              width: 100%;
              height: 4rem;
              align-items: center;
              display: grid;
              grid-template-columns: 20% 20% 45% 15%;
              letter-spacing: 0.5px;
              border-top: 1px solid $gray-1-0;
            }
          }
        }
        .total-devis {
          display: flex;
          justify-content: flex-end;
          margin-top: 4rem;
          margin-right: 8%;
          div {
            gap: 5.5rem;
            display: flex;
            align-items: center;

            h1,
            span {
              font-size: 1.2rem;
              color: $black-4;
              font-weight: 600;
            }
          }
        }
      }
    }
    .livreur {
      margin-top: 15%;
      display: flex;
      gap: 25%;
      border-bottom: 1px solid $gray-1-0;
      padding-bottom: 2rem;
      .left {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        h4 {
          color: $black-0;
          letter-spacing: 0.5px;
        }
        select {
          border-radius: 0.25rem;
          border: 1px solid #cbd5e1;
          background: #fff;
          width: 16.25rem;
          height: 2.5rem;
          flex-shrink: 0;
          padding: 0 10px;
        }
      }
    }
    .footer {
      display: flex;
      gap: 5rem;
      margin-top: 4rem;
      padding-bottom: 3rem;
      .left {
        ul {
          display: flex;
          flex-direction: column;
          li {
            display: inline-block;
            font-size: 16px;
            letter-spacing: 1px;
            color: $black-4;
            font-weight: 600;
          }
        }
      }
    }
  }
}
