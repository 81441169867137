@use '../../../sass' as *;

#dc-superadmin{
    width: 100%;
	@media (max-width:1300px) {
			width: 69rem;
		}
    #dc-superadmin-header{
        @include header-page-add-see;
    }
    #headComplain{
        margin-top:1rem;  
        width: 100%;
        .c-line{
            margin-top: 1rem;
            display: flex;
            align-items: center;
            h2{
                font-size: 1.2rem;
                color: $green-1;
                width: 7rem;
            }
            span{
                width: 70%;
                display: inline-block;
                color: $black-2;
                font-size: 1.2rem;
            }
        }
    }
    #object{
        margin-top: 2rem;
        display: flex;
        flex-direction: column;
        h2{
            color: $black-2;
        }
        span{
            color: $black-2-0;
            font-size: 1.1rem;
        }
    }
    #message-complain{
        margin-top: 2rem;
        h2{
            color: $black-2;
        }
        p{
            color: $black-2-0;
            font-size: 1.1rem;
            width: 50%;
        }
    }
}