@use '../../../sass/' as *;

#complainDetails{
    width: 100%;
    @media (max-width: 1300px) {
        width: 69rem;
    }
    #details-complain-header{
        @include header-page-add-see;
    }
    #headComplain{
        margin-top:2em;  
        width: 100%;
        .c-line{
            margin-top: 0.4rem;
            display: flex;
            align-items: center;
            h2{
                font-size: 1.2rem;
                color: $green-1;
                width: 7rem;
            }
            span{
                width: 50%;
                display: inline-block;
                color: $black-4;
                font-size: 1.2rem;
            }
        }
    }
    #object{
        margin-top: 2rem;
        display: flex;
        flex-direction: column;
        h2{
            color: $black-6;
        }
        span{
            color: $black-4;
            font-size: 1.1rem;
        }
    }
    #message-complain{
        margin-top: 1rem;
        h2{
            color: $black-6;
        }
        p{
            color: $black-4;
            font-size: 1.1rem;
            width: 50%;
        }
    }
    #statut-complain{
        margin: 1rem 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 1.5rem;
        #radio-status{
            display: flex;
            flex-direction: column;
            gap: 1rem;
            label {
                display: flex;
                gap: 0.5rem;
                input[type="radio"] {
                    width: 1.5rem;
                    height: 1.5rem;
                    accent-color: $green-1;
                }
            }
        }
        h2{
            color: $black-6;
        }
    }
    .confirm {
        margin-top: 1.5rem;
        border-radius: 0.125rem;
        background: $green-1;
        color: white;
        padding: 0.7rem 1.7rem;
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
}