@use '../utilities/' as *;

form {
  display: flex;
  flex-direction: column;

  label {
    display: inline-block;
    font-size: 16px;
    letter-spacing: 1px;
    color: $black-5;
    font-weight: 600;
  }

  input {
    display: inline-block;
    font-size: 16px;
    letter-spacing: 1px;
    padding: 0.5rem 1rem;
    border-radius: $border-radius;
    border: 1px solid $gray-1;
    color: $gray-4;
  }

  .label-input {
    display: flex;
    flex-direction: column;
  }

  textarea {
    color: $gray-4;
    font-size: 16px;
    border-radius: $border-radius;
    border: 1px solid $gray-1;
    resize: none;
    padding: 0.5rem 1rem;
  }

  .field {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    width: 70%;
    gap: 0.3rem;

    input {
      width: 100%;
    }
  }

  .description-area {
    margin-top: 1.3rem;

    textarea {
      border: 1px solid $gray-1;
      border-radius: $border-radius;
      color: $gray-4;
      resize: none;
      padding: 1rem;
      width: 90%;
    }
  }

  .info-dropdown {
    margin-top: 1.3rem;
    width: 90%;
    padding: 0.5rem 0.5rem;
    background-color: $white;
    border: $input-border;
    border-radius: $border-radius;

    select {
      width: 100%;
      background-color: $white;
      color: $gray-4;
      font-size: 16px;

      option {
        width: 90%;
        display: inline-block;
        font-size: 14px;
        color: $gray-4;
      }
    }
  }

  .submit-btns {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 1rem;

    .add-btns {
      gap: 1rem;
      display: flex;
    }
  }

  .add-shop {
    cursor: pointer;
    color: $black-6;
    width: 100%;
    padding: 1rem;
    border: 1px solid $black-6;
    margin-bottom: 2rem;
    background-color: $white;
    font-weight: 600;
    font-size: 14px;
  }

  .shop {
    padding: 1rem;
    box-shadow: $box-shadow;
    border: $primary-border;
    border-radius: $border-radius;
    margin-bottom: 1.3rem;
    background-color: $white;

    .del-shop {
      display: flex;
      justify-content: flex-end;

      .box-bordered {
        height: 2rem;
        width: 2rem;
        @include flexcenter;
        background-color: $blue-2;
        cursor: pointer;
        // border: 1px solid $black-5;
        border-radius: 50%;

        .del-shop-icon {
          font-size: 1.2rem;
          color: $black-5;
        }
      }
    }

    label {
      margin-bottom: 0.3rem;
    }

    input {
      width: 90%;
      margin-bottom: 1.3rem;
    }

    input[type="button"] {
      background-color: $green-1;
      color: $white;
      cursor: pointer;
      border: none;
    }

    .delivery-details {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      label {
        margin-bottom: 0;
      }

      .info-dropdown {
        margin-top: 0;
      }

      .info-1 {
        display: flex;
        justify-content: space-between;
        width: 90%;

        // margin-bottom: .4rem;
        input {
          width: 48%;
        }
      }
    }
  }
}
.field-image-upload {
  @extend .field;
  width: 90%;

  .imgBlock {
    border: 3px dashed $gray-1;
    border-radius: $border-radius;

    #imgUpload {
      height: 10rem;
      overflow: hidden;
      position: relative;

      #imgUploading {
        height: 100%;
        width: 100%;
        position: relative;
        outline: none;
        border: 0;

        &::before {
          content: "";
          background-repeat: no-repeat;
          background-position: center;
          background-size: 50px 50px;
          background-image: url(../../assets/icons/svg/gallery.svg);
          margin: auto;
          position: absolute;
          width: 100%;
          height: 97%;
          top: 0;
          left: 0;
          cursor: pointer;
          background-color: $white;
          text-align: center;
        }
      }

      .fileUp {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: $white;
        cursor: pointer;

        img {
          width: 100%;
          height: 100%;
          display: block;
          object-fit: contain;
        }
      }
    }
  }
}
