// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------
@use "../utilities/" as *;

.button-nature {
  @include flexcenter;
  color: $green-1;
  background-color: $white;
  font-size: 1.3rem;
  height: 48px;
  border-radius: 2px;
  padding: 0.7rem 1.5rem;
  cursor: pointer;
  box-shadow: $box-shadow;
  a {
    color: inherit;
    @include flexcenter;
  }
}
.button-nature-inactive {
  @include flexcenter;
  color: $green-4;
  background-color: transparent;
  font-size: 1.3rem;
  height: 48px;
  border-radius: 2px;
  padding: 0.7rem 1.5rem;
  cursor: pointer;
}
.button-primary {
  @include flexcenter;
  cursor: pointer;
  transform: none !important;
  background-color: $green-1;
  color: $white;
  height: 48px;
  font-size: 1.2rem;
  border-radius: 2px;
  padding: 1rem 1.5rem;
  box-shadow: $box-shadow;
  &:disabled {
    cursor: not-allowed;
    background-color: $green-4;
  }
}
.button-primary-inactive {
  @include flexcenter;
  cursor: pointer;
  background-color: $green-4;
  color: $white;
  height: 48px;
  font-size: 1.2rem;
  border-radius: 2px;
  padding: 1rem 1.5rem;
}
.button-danger {
  @include flexcenter;
  cursor: pointer;
  background-color: $danger;
  color: $white;
  height: 48px;
  font-size: 1.2rem;
  border-radius: 2px;
  padding: 1rem 1.5rem;
  box-shadow: $box-shadow;
}
