@use '../../../sass/' as *;

#addClient {
  width: 100%;
  @media (max-width: 1300px) {
    width: 69rem;
  }
  #add-header {
    @include header-page-add-see;
  }

  form {
    width: 37rem;
    h2,
    h3 {
      color: $black-6;
    }
    label {
      margin-bottom: 0.3rem;
    }
    .user-infos,
    .shop {
      background-color: $white;
      padding: 1rem;
      box-shadow: $box-shadow;
      border: $primary-border;
      border-radius: $border-radius;
      margin-bottom: 1.3rem;
      .line {
				display: flex;
				align-items: center;
				input {
					margin: .7rem;
					cursor: pointer;
				}
			}
      .form-line {
        margin-top: 0.8rem;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        width: 90%;

        .frag {
          width: 48%;
          display: flex;
          flex-direction: column;
        }
      }
      .field {
        width: 90%;
      }
    }
    .add-shop-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .shop {
      padding-bottom: 2.2rem;
    }
    .add-shop {
      padding: 0.5rem;
      background-color: $white;
      box-shadow: $box-shadow;
      font-size: 1rem;
      border: 1px solid $black-0;
      font-weight: 500;
      letter-spacing: 0.5px;
      margin-bottom: 2rem;
    }
  }
}
