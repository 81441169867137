@use '../../../sass/' as *;


#supProfile{
    width: 100%;
    @media (max-width:1300px) {
        width: 69rem;
    }
    #sup-profile-header{
        @include header-page-add-see;
        h1{
            font-size:2rem;
        }
    }

    .infos{
        margin-top: 2rem;
        width: 80%;
        .line{
            width: 100%;
            margin-top: 1rem;
            display: flex;
            align-items: center;
            letter-spacing: .5px;
            h2{
                font-size: 1.1rem;
                display: inline-block;
                width: 30%;
            }
            span{
                color: $black-4;
                width: 70%;
            }
        }
    }
}
