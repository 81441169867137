@use '../../../sass/' as *;

#complainAdminDetails {
  width: 100%;
  @media (max-width: 1300px) {
    width: 60rem;
  }
  #details-complain-header {
    @include header-page-add-see;
    .buttons {
      display: flex;
      gap: 1rem;
    }
  }
  .button-refuser {
    display: flex;
    align-items: center;
    height: 48px;
    font-size: 1.2rem;
    border-radius: 2px;
    padding: 1rem 1.5rem;
    border: 1px solid #f37272;
    color: #f37272;
    background-color: white;
    cursor: pointer;
  }
  #headComplain {
    margin-top: 2em; 
    width: 100%;
    .infos {
      margin-top: 0.4rem;
      div {
        display: flex;
        gap: 1rem;
      }
      h2 {
        font-size: 1.2rem;
        color: $green-1;
        width: 7rem;
      }
      span {
        width: 50%;
        display: inline-block;
        color: $black-6;
        font-size: 1.2rem;
      }
    }
  }
  .content {
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    p{
      width: 50rem;
    }
    .refus {
      width: 20px;
      height: 20px;
      color: white;
      font-size: 0.8rem;
      background-color:red;
      border-radius: 50%;
      display: grid;
      place-items: center;
      margin-left: 2px;
    }
    h3 {
      color: $black-6;
    }
  }
  #img{
    margin-top: 1rem;
    h3 {
      color: $black-6;
      margin-bottom: 0.75rem;
    }
    .image {
      .fileUp {
        img {
          object-fit: contain;
          width: 29rem;
          height: 13.375rem;
        }
      }
    }
  }
}
