@use "../../../sass/" as *;

#clientListPageAdmin {
  width: 100%;
  @media (max-width: 1300px) {
    width: 69rem;
  }
  h1 {
    font-size: 1.7rem;
  }
  .usersContent {
    width: 100%;
    #users {
      width: 100%;
      border-top: 1px solid $gray-1-0;
      border-bottom: 1px solid $gray-1-0;

      #titres {
        width: 100%;
        display: grid;
        grid-template-columns: 2% 13% 15% 10% 10% 13% 10% 13% 10% 4%;
        height: 2.5rem;
        align-items: center;
        font-weight: 600;
        td {
          font-size: 14px;
          text-align: left;
          letter-spacing: 1px;
        }
      }
      tbody {
        width: 100%;
        .user {
          z-index: 3;
          min-height: 3.5rem;
          border-top: 1px solid $gray-1-0;
          display: grid;
          align-items: center;
          letter-spacing: 0.5px;
          grid-template-columns: 2% 13% 15% 10% 10% 13% 10% 13% 10% 4%;
          td:not(.last-user-element) {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 160px;
          }
          td:nth-child(-n + 3) {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 160px;
            &:hover {
              max-width: none;
              white-space: wrap;
              border-radius: 5px;
              cursor: pointer;
            }
          }
          .last-user-element {
            display: flex;
            align-items: center;
            height: 100%;
            gap: 1rem;
            .link-user {
              display: flex;
            }
            .see-user,
            .user-option {
              cursor: pointer;
              font-size: 1.2rem;
              color: $black-0;
            }
          }
          .user-status {
            border-radius: 36px;
            width: 5rem;
            color: $green-3-1;
            background-color: $green-3-0;
            text-align: center !important;
            padding: 0.3rem 0rem;
            font-weight: 500;
            letter-spacing: 0.5px;
            &.activé {
              color: $danger;
              background-color: $orange-3;
            }
          }
          .contain {
            display: inline-block;
            position: relative;
            @include flexcenter;
          }
        }
      }
    }
  }
}
