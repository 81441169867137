@use '../../../sass/' as *;

#complainClientDetails {
  width: 100%;
  @media (max-width: 1300px) {
    width: 60rem;
  }
  #details-complain-header {
    @include header-page-add-see;
    .buttons {
      display: flex;
      gap: 1rem;
    }
  }
  #headComplain {
    margin-top: 2em;
    width: 100%;
    .infos {
      margin-top: 0.4rem;
      div {
        display: flex;
        gap: 1rem;
      }
      h2 {
        font-size: 1.2rem;
        color: $green-1;
        width: 7rem;
      }
      span {
        width: 100%;
        display: inline-block;
        color: $black-6;
        font-size: 1.2rem;
      }
    }
  }
  #object {
    h2 {
      margin-bottom: 1rem;
    }
    #radio-status {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      label {
        display: flex;
        gap: 0.5rem;
        input[type="radio"] {
          width: 1.5rem;
          height: 1.5rem;
          accent-color: $green-1;
        }
      }
    }
  }
  #montant {
    h2 {
      color: $black-6;
      margin-bottom: 1rem;
      margin-top: 2.5rem;
    }
    input {
      width: 34.5rem;
      height: 3rem;
      border-radius: 0.125rem;
      border: 1px solid #cbd5e1;
      background: #fff;
      padding: 1rem;
    }
  }
  #image {
    h2 {
      color: $black-6;
      margin-bottom: 1rem;
      margin-top: 2.5rem;
    }
    .image {
      border-radius: 0.125rem;
      border: 1px dashed $green-1;
      background: $white;
      width: 34.5rem;
      height: 13.375rem;
      flex-shrink: 0;
      display: grid;
      place-items: center;
      position: relative;
      .custom-file-upload {
        border-radius: 0.125rem;
        border: 1px solid $green-1;
        background: $white;
        display: inline-block;
        width: 11.875rem;
        height: 2.5rem;
        padding: 6px 12px;
        text-align: center;
        cursor: pointer;
        input[type="file"] {
          display: none;
        }
      }
      .fileUp {
        img {
          object-fit: contain;
          width: 34.5rem;
          height: 13.375rem;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
  }
  .devis-table {
    width: 100%;
    margin-top: 4rem;
    .offres {
      width: 100%;
      border-top: 1px solid $gray-1-0;
      border-bottom: 1px solid $gray-1-0;

      #titres {
        width: 100%;
        height: 3rem;
        align-items: center;
        font-weight: 600;
        display: grid;
        grid-template-columns: 4% 18% 18% 25% 20% 15%;
        letter-spacing: 0.5px;
        td {
          color: $black-0;
          letter-spacing: 0.5px;
        }
      }
      tbody {
        .elements {
          width: 100%;
          height: 4rem;
          align-items: center;
          display: grid;
          grid-template-columns:4% 18% 18% 25% 20% 15%;
          letter-spacing: 0.5px;
          border-top: 1px solid $gray-1-0;
          &.active {
            td {
              color: #727272;
            }
            input[type="number"] {
              border-radius: 0.25rem;
              border: 1px solid #cbd5e1;
              opacity: 0.5;
              background: #fff;
            }
          }
          .qyt {
            input {
              width: 2.875rem;
              height: 2.5rem;
              border-radius: 0.25rem;
              border: 1px solid #cbd5e1;
              background: #fff;
              text-align: center;
            }
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
          }
        }
      }
    }
    .total-devis {
      display: flex;
      justify-content: flex-end;
      margin-top: 4rem;
      margin-right: 8%;
      div {
        gap: 5.5rem;
        display: flex;
        align-items: center;

        h1,
        span {
          font-size: 1.2rem;
          color: $black-4;
          font-weight: 600;
        }
      }
    }
  }
}
