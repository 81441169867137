@use '../../../sass/' as *;


#complainClientDetails{
    width: 100%;
    @media (max-width: 1300px) {
        width: 60rem;
    }
    #details-complain-header{
        @include header-page-add-see;
    }
    #headComplain{
        margin-top:2em;  
        width: 100%;
        .c-line{
            margin-top: 0.4rem;
            width: 20rem;
            display: flex;
            align-items: center;
            h2{
                font-size: 1.2rem;
                color: $green-1;
                width: 4rem;
            }
            span{
                width: 50%;
                display: inline-block;
                color: $black-6;
                font-size: 1.2rem;
            }
        }
    }
    #object{
        margin-top: 2rem;
        display: flex;
        flex-direction: column;
        h2{
            color: $black-6;
        }
        span{
            color: $black-5;
            font-size: 1.1rem;
        }
    }
    #message-complain{
        margin-top: 2rem;
        h2{
            color: $black-6;
        }
        p{
            color: $black-6;
            font-size: 1.1rem;
            width: 50%;
        }
    }
    #statut-complain{
        margin-top: 2rem;
        display: flex;
        align-items: center;
        gap: 3rem;
        #drop-status{
            border: $primary-border;
            border-radius: 2px solid $gray-1-0;
            width: 10%;
            background-color: $white;
            select{
                padding: .5rem;
                color: $black-6;
                font-size: 1.1rem;
                background-color: $white;
            }
        }
    }
}