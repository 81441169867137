@use '../../../sass/' as *;

#updateBill {
	.update-bill-header {
		@include header-page-add-see;
	}
	#title-bill {
		width: 60%;
		margin: 2rem auto;
		text-align: center;
		h1 {
			color: $black-0;
		}
	}
	.container {
		width: 100%;
		@media (max-width: 1300px) {
			width: 63rem;
		}
		form {
			padding-bottom: 2rem;
			.credentials {
				margin-top: 2rem;
				display: flex;
				gap: 3rem;
				width: 100%;
				.left {
					display: flex;
					flex-direction: column;
					border: $primary-border;
					border-radius: $border-radius;
					padding: 1rem;
					width: 40%;
					.left-el {
						display: flex;
						flex-direction: column;
						margin-bottom: .5rem;
						label {
							color: $green-1;
							display: inline-block;
							font-size: 18px;
							font-weight: 600;
							letter-spacing: 1px;
						}
						input {
							text-align: center;
							width: 50%;
							font-weight: normal;
							color: $black-0;
							border: none;
						}
					}
				}
				.right {
					@extend .left;
					
					.right-el {
						display: flex;
					flex-direction: column;
					margin-bottom: .5rem;
					label {
						color: $green-1;
						display: inline-block;
						font-size: 18px;
						font-weight: 600;
						letter-spacing: 1px;
					}
					span {
						color: $gray-3;
					}
					}
				}
			}
			.devis {
				.devis-head {
					margin-top: 3rem;
					display: flex;
					flex-direction: column;
					h1 {
						color: $black-0;
					}
					span {
						display: block;
						width: 80px;
						height: 4px;
						background-color: $green-1;
					}
				}
				.devis-table {
					width: 100%;
					margin-top: 4rem;
					.offres {
						width: 100%;
						border-top: 1px solid $gray-1-0;
						border-bottom: 1px solid $gray-1-0;

						#titres {
							width: 100%;
							height: 3rem;
							align-items: center;
							font-weight: 600;
							display: grid;
							grid-template-columns: 12% 28% 10% 15% 10% 15% 10%;
							letter-spacing: .5px;
							td {
								color: $black-0;
								letter-spacing: .5px;
							}
						}
						tbody {
							.elements {
								width: 100%;
								height: 4rem;
								align-items: center;
								display: grid;
								grid-template-columns: 12% 28% 10% 15% 10% 15% 10%;
								letter-spacing: .5px;
								border-top: 1px solid $gray-1-0;
								td {
									width: 100%;
									
								}
							}
						}
					}
					.total-devis {
						display: flex;
						justify-content: flex-end;
						margin-top: 4rem;
						margin-right: 8%;
						div {
							gap: 5.5rem;
							display: flex;
							align-items: center;

							h1,
							span {
								font-size: 1.2rem;
								color: $black-4;
								font-weight: 600;
							}
						}
					}
				}
				.billTable {
					margin-top: 10rem;
					width: 100%;
					#bt-principal {
						width: 100%;
						border: 2px solid $gray-1-0;
						#firstBigRow {
							width: 100%;
							display: grid;
							grid-template-columns: 44% 44% 12%;
							#bt-nested-1 {
								border-right: 2px solid $gray-1-0;
								width: 100%;
								#bt-head-1 {
									display: grid;
									grid-template-columns: repeat(4, 25%);
									align-items: center;
									height: 3rem;
									td {
										color: $black-0;
										font-weight: 600;
										letter-spacing: .5px;
										padding-left: 0.2rem;
									}
								}
								.bt-tl-1 {
									height: 3rem;
									display: grid;
									grid-template-columns: repeat(4, 25%);
									align-content: center;
									border-top: 2px solid $gray-1-0;
									td {
										height: 100%;
										padding-left: 0.2rem;
										width: 100%;
										input {
											text-align: center;
											width: 80%;
										}
									}
								}
							}
							#bt-nested-2 {
								border-right: 2px solid $gray-1-0;
								width: 100%;
								#bt-head-2 {
									display: grid;
									grid-template-columns: repeat(4, 25%);
									align-items: center;
									height: 3rem;
									td {
										color: $black-0;
										font-weight: 600;
										letter-spacing: .5px;
										padding-left: 0.5rem;
									}
								}
								.bt-tl-2 {
									height: 3rem;
									display: grid;
									grid-template-columns: repeat(4, 25%);
									align-content: center;

									td {
										height: 100%;
										padding-left: 0.5rem;
										width: 100%;
										input {
											text-align: center;
											width: 95%;
										}
									}
								}
								#bt-tl-2 {
									border-top: 2px solid $gray-1-0;
								}
							}
							#bt-nested-3 {
								width: 100%;
								#bt-head-3 {
									width: 100%;
									height: 3rem;
									text-align: center;

									td {
										color: $black-0;
										font-weight: 600;
										letter-spacing: .5px;
									}
								}
								.bt-tl-3 {
									width: 100%;
									height: 3rem;
									align-content: center;

									td {
										height: 100%;
										text-align: right;
										padding-right: 1rem;
										border-top: 2px solid $gray-1-0;
										input {
											text-align: center;
											width: 90%;
											font-weight: 600;
											letter-spacing: .5px;
										}
									}
								}
							}
						}
						#secondBigRow {
							width: 100%;
							display: grid;
							grid-template-columns: 44% 56%;
							#bt-nested-4 {
								width: 100%;
								border-top: 2px solid $gray-1-0;
								border-right: 2px solid $gray-1-0;
								.bt-head-4 {
									height: 3rem;
									display: grid;
									grid-template-columns: repeat(4, 25%);
									align-content: center;
                                    align-items: center;
									td {
										letter-spacing: .5px;
										padding-left: 0.2rem;
										color: $black-0;
										font-weight: 600;
										input {
											color: $black-0;
											font-weight: 600;
											width: 90%;
											text-align: center;
										}
									}
								}
							}
							#bt-nested-5 {
								width: 100%;
								border-top: 2px solid $gray-1-0;
								#bt-head-5 {
									height: 3rem;
									display: grid;
									grid-template-columns: 35% 65%;
									align-content: center;
									td {
										padding-left: 0.5rem;
										font-weight: 600;
										color: $black-0;
										letter-spacing: .5px;
									}
								}
								.bt-tl-5 {
									height: 3rem;
									display: grid;
									grid-template-columns: 35% 65%;
									align-content: center;
									td {
										padding-left: .5rem;
                                        input{
                                            width: 90%;
                                        }
									}
								}
							}
						}
					}
					.billEnd {
						display: flex;
						flex-direction: column;
						margin-top: 3rem;
						gap: 1rem;
						h2 {
							color: $black-0;
							letter-spacing: .5px;
							font-size: 1.3rem;
						}
						span{
							color: $black-4;
						}
						input {
							color: $black-4;
                            width: 55%;
						}
					}
				}
			}
		}
        .footer {
            display: flex;
            gap: 5rem;
			border-top: 1px solid $gray-1-0;
            margin-top: 4rem;
			padding-bottom: 3rem;
			padding-top: 3rem;
            .left{
                ul{
                    display: flex;
                    flex-direction: column;
                    li {
                        display: inline-block;
                        font-size: 16px;
                        letter-spacing: 1px;
                        color: $black-6;
                        font-weight: 600;
                    }
                }
            }
        }
	}
}
