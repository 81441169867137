@use '../../../sass/' as *;

#orderPageCommercial {
  width: 100%;
  @media (max-width: 1300px) {
    width: 69rem;
  }
  #head {
    padding-block: 1.5rem;
    @include header-page-add-see;
  }
  .modalcontainer {
    box-shadow: none;
    .modal {
      background-color: $white;
      height: max-content;
      width: 35rem;
      padding: 0.5rem 2rem;
      border-radius: 4px;
      border: 1px solid $gray-1-1;
      gap: 1rem;
      display: none;
      margin: 0;
      flex-direction: column;
      top: 25%;

      form {
        .field {
          gap: 0.5rem;
          width: 100%;
          input {
            width: 100% !important;
          }
          .dynamicData {
            border-right: 1px solid $gray-1;
            border-left: 1px solid $gray-1;
            border-bottom: 1px solid $gray-1;
            display: flex;
            flex-direction: column;
            transform: translateY(-0.6em);
            border-radius: $border-radius;
            span {
              display: inline;
              cursor: pointer;
              padding: 0.4rem;
            }
          }
        }
        .modalbtn {
          margin-top: 2.5rem;
          margin-bottom: 2rem;
        }
      }
      &.active {
        display: flex;
      }
    }
    &.open {
      position: fixed;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      z-index: 1000;
      @include flexcenter;
      transition: background-color 0.15s ease-out;
      background-color: rgba(0, 0, 0, 0.75);
    }
  }

  #ordersContentAdmin {
    width: 100%;
    @media (max-width: 1300px) {
      width: 69rem;
    }
    #orders {
      width: 100%;
      border-top: 1px solid $gray-1-0;
      border-bottom: 1px solid $gray-1-0;
      #titres {
        width: 100%;
        display: grid;
        grid-template-columns: 3% 6% 8% 15% 22% 17% 10% 10% 10%;
        height: 2.5rem;
        align-items: center;
        font-weight: 600;
        td {
          font-size: 14px;
          text-align: left;
          letter-spacing: 1px;
          &.center {
            margin: auto;
          }
        }
      }
      tbody {
        width: 100%;
        .order {
          height: 3rem;
          border-top: 1px solid $gray-1-0;
          display: grid;
          align-items: center;
          grid-template-columns: 3% 6% 8% 15% 22% 17% 10% 10% 10%;
          letter-spacing: 0.5px;
          font-size: $font-size - 0.3rem;
          .center {
            margin: auto;
          }
          .last-order-element {
            display: flex;
            align-items: center;
            height: 100%;
            gap: 1rem;
            margin: auto;
            .link-order {
              display: flex;
            }
            .see-order,
            .order-option {
              cursor: pointer;
              font-size: 1.2rem;
              color: $black-0;
            }
          }
          .contain {
            display: inline-block;
            position: relative;
            @include flexcenter;
          }
          td:nth-child(-n + 5) { 
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 160px;
            transition: 0.1s all ease-in-out;
          }
        }
        .status {
          border-radius: 36px;
          width: 5rem;

          text-align: center !important;
          padding: 0.3rem 0rem;
          font-weight: 500;
          letter-spacing: 0.5px;
          &.confirmer {
            color: $green-3-1;
            background-color: $green-3-0;
          }
          &.en-cours {
            color: #f59e0b;
            background: #fef3c7;
          }
        }
      }
    }
  }
  .modalcontainer {
    .confirm {
      font-size: 22px;
      text-align: center;
    }
    .select {
      display: flex;
      flex-direction: column;
      gap: 5px;
      select {
        border-radius: 0.25rem;
        border: 1px solid #cbd5e1;
        background: #fff;
        height: 2.5rem;
        flex-shrink: 0;
        padding: 0 10px;
      }
    }
    .btn {
      margin: auto 0px 7px auto;
      margin-top: 1rem;
      display: flex;
      gap: 10px;
    }
  }
}
