@use '../../../sass/' as *;

#add-user_admin {
	width: 100%;
	@media (max-width: 1300px) {
		width: 69rem;
	}
	padding-bottom: 5rem;
	h2 {
		margin-bottom: .8rem;
		color: $black-6;
	}
	.add-user-header {
		@include header-page-add-see ;
		margin-bottom: 1.3rem;
	}
	form {
		width: 37rem;
		.user-infos {
			background-color: $white;
			padding: 1rem;
			box-shadow: $box-shadow;
			border: $primary-border;
			border-radius: $border-radius;
			margin-bottom: 1.3rem;
			.line {
				display: flex;
				align-items: center;
				input {
					margin: .7rem;
					cursor: pointer;
				}
			}
		}
		.user-details {
			background-color: $white;
			padding: 1rem;
			box-shadow: $box-shadow;
			border: $primary-border;
			border-radius: $border-radius;
			margin-bottom: 1.3rem;
			.form-line {
				display: flex;
				justify-content: space-between;
				width: 90%;
				.frag {
					width: 48%;
					display: flex;
					flex-direction: column;
				}
			}
			.field {
				width: 90%;
			}
		}
		.add-shop-title{
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
		.shop {
			padding: 1rem;
			box-shadow: $box-shadow;
			border: $primary-border;
			border-radius: $border-radius;
			margin-bottom: 1.3rem;
			background-color: $white;
			.del-shop{
				display: flex;
				justify-content: flex-end;
				.box-bordered{
					height: 2rem;
					width: 2rem;
					@include flexcenter;
					background-color: $blue-2;
					cursor: pointer;
					// border: 1px solid $black-5;
					border-radius: 50%;
					.del-shop-icon{
						font-size: 1.2rem;
						color: $black-5;
					}
				}
			}
			label {
				margin-bottom: .3rem;
			}
			input {
				width: 90%;
				margin-bottom: 1.3rem;
			}
			input[type=button] {
				background-color: $green-1;
				color: $white;
				cursor: pointer;
				border: none;
			}
			.delivery-details {
				display: flex;
				flex-direction: column;
				gap: .5rem;
				label{
					margin-bottom: 0;
				}
				.info-dropdown{
					margin-top: 0;
				}
				.info-1 {
					display: flex;
					justify-content: space-between;
					width: 90%;
					// margin-bottom: .4rem;
					input {
						width: 48%;
					}
				}
			
			}
		}
		
	}
}
