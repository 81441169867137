@use '../../../sass/' as *;

#updateReturn{
    .update-return-header {
        @include header-page-add-see;
	}
    .container{
        width: 100%;
            @media (max-width: 1300px) {
                width: 63rem;
            }
           form{
            border-bottom: 1px solid $gray-1-0;
            padding-bottom: 2rem;
            .credentials {
                margin-top: 2rem;
                display: flex;
                gap: 3rem;
                width: 100%;
                .left {
                    display: flex;
                    flex-direction: column;
                    border: $primary-border;
                    border-radius: $border-radius;
                    padding: 1rem;
                    width: 40%;
                    .left-el {
                        display: flex;
                        flex-direction: column;
                        margin-bottom: .5rem;
                        label {
                            color: $green-1;
                            display: inline-block;
                            font-size: 18px;
                            font-weight: 600;
                            letter-spacing: 1px;
                        }
                        input {
                            text-align: center;
                            width: 50%;
                            font-weight: normal;
                            color: $black-0;
                            border: none
                        }
                    }
                }
                .right {
                    @extend .left;
                    label {
                        color: $green-1;
                        display: inline-block;
                        font-size: 18px;
                        font-weight: 600;
                        letter-spacing: 1px;
                    }
                    .right-el {
                        height: 11.5rem;
                        background-color: $white;
                        border: 1px solid $gray-1-0;
                        padding: 1rem;
                        display: flex;
                        flex-direction: column;
                        gap: .5rem;
                        width: 70%;
                        input {
                            display: inline-block;
                            font-weight: normal;
                            border: none;
                            padding: 0;
                            color: $black-0;
                        }
                    }
                }
            }
            .devis{
                .devis-head {
                    margin-top: 3rem;
                    display: flex;
                    flex-direction: column;
                    h1 {
                        color: $black-0;
                    }
                    span {
                        display:block;
                        width: 80px;
                        height: 4px;
                        background-color: $green-1;
                    }
                }
                .devis-table {
                    width: 100%;
                    margin-top: 4rem;
                    .offres {
                        width: 100%;
                        border-top: 1px solid $gray-1-0;
                        border-bottom: 1px solid $gray-1-0;
    
                        #titres {
                            width: 100%;
                            height: 3rem;
                            align-items: center;
                            font-weight: 600;
                            display: grid;
                            grid-template-columns: 20% 20% 40% 10%;
                            letter-spacing: .5px;
                            td{
                                color: $black-0;
                                letter-spacing: .5px;
                            }
                        }
                        tbody {
                            .elements {
                                width: 100%;
                                height: 4rem;
                                align-items: center;
                                display: grid;
                                grid-template-columns: 20% 20% 40% 10%;
                                letter-spacing: .5px;
                                border-top: 1px solid $gray-1-0;
                                td {
                                    input {
                                        width: 80%;
                                        background-color: $white;
                                        color: $black-0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .livreur{
                margin-top: 15%;
                display: flex;
                gap: 25%;
                
                
                .left{
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;
                    h4{
                        color: $black-0;
                        letter-spacing: .5px;
                    }
                    input{
                        text-align: center;
                    }
                }
            }
           }
           .footer {
            display: flex;
            gap: 5rem;
            margin-top: 3rem;
            padding-bottom: 3rem;
            .left{
                ul{
                    display: flex;
                    flex-direction: column;
                    li {
                        display: inline-block;
                        font-size: 16px;
                        letter-spacing: 1px;
                        color: $black-4;
                        font-weight: 600;
                    }
                }
            }
        }
    }
}