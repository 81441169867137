@use '../../../sass/' as *;
#profilePage {
  width: 100%;
  @media (max-width: 1300px) {
    width: 69rem;
  }
  .main {
    width: 100%;
    display: flex;
    gap: 10%;
    .edit-profile {
      width: 50%;
      form {
        gap: 1rem;
        .label-input {
          gap: 0.5rem;
        }
        .edit-profile-button {
          display: flex;
          justify-content: flex-end;
          gap: 1rem;
        }
      }
    }
    .section {
      width: 40%;
      .infos {
        width: 100%;
        h2{
          color: $black-6;
          margin: 1.5rem 0 .5rem;
          font-size: 19px;
        }
        .line {
          display: flex;
          flex-wrap: nowrap;
          padding-top: 0.7rem;
          padding-bottom: 0.7rem;
          h4 {
            width: 50%;
          }
          span {
            width: 50%;
            color: $black-3;
            display: inline-block;
          }
        }
      }
      .infos-links {
        margin-top: 2rem;
        li {
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;
          color: $green-1;
          cursor: pointer;
        }
      }
      &.active {
        margin: 0 auto;
      }
    }
  }
}
