@use '../../../sass/' as *;

#returnDetails{
    width: 100%;
	@media (max-width: 1300px) {
		width: 69rem;
	}
	.details-return-header {
		@include header-page-add-see;
	}
	#title-return {
		width: 60%;
		margin: 2rem auto;
		text-align: center;
		h1 {
			color: $black-0;
		}
	}
	.container {
		.credentials {
			margin-top: 2rem;
			display: flex;
			gap: 3rem;
			width: 100%;
			.left {
				display: flex;
				flex-direction: column;
				border: $primary-border;
				border-radius: $border-radius;
				padding: 1rem;
				width: 40%;
				.left-el {
					display: flex;
					flex-direction: column;
					margin-bottom: .5rem;
					label {
						color: $green-1;
						display: inline-block;
						font-size: 18px;
						font-weight: 600;
						letter-spacing: 1px;
					}
					span {
						color: $black-4;
					}
				}
			}
		}
		.devis {
			.devis-head {
				margin-top: 3rem;
                display: flex;
                flex-direction: column;
				h1 {
					color: $black-0;
				}
				span {
                    display:block;
					width: 80px;
					height: 4px;
					background-color: $green-1;
				}
			}
            .devis-table {
				width: 100%;
				margin-top: 4rem;
				.offres {
					width: 100%;
					border-top: 1px solid $gray-1-0;
					border-bottom: 1px solid $gray-1-0;

					#titres {
						width: 100%;
						height: 3rem;
						align-items: center;
						font-weight: 600;
						display: grid;
						grid-template-columns: 20% 20% 25% 20% 15%;
						letter-spacing: .5px;
                        td{
                            color: $black-0;
                            letter-spacing: .5px;
                        }
					}
					tbody {
						.elements {
							width: 100%;
							height: 4rem;
							align-items: center;
							display: grid;
							grid-template-columns: 20% 20% 25% 20% 15%;
							letter-spacing: .5px;
							border-top: 1px solid $gray-1-0;
						}
					}
				}
				.total-devis {
					display: flex;
					justify-content: flex-end;
					margin-top: 4rem;
					margin-right: 8%;
					div {
						gap: 5.5rem;
						display: flex;
						align-items: center;

						h1,
						span {
							font-size: 1.2rem;
							color: $black-6;
							font-weight: 600;
						}
					}
				}
			}
		}
        .livreur{
            margin-top: 15%;
            display: flex;
            gap: 25%;
            border-bottom: 1px solid $gray-1-0;
			padding-bottom: 2rem;
            .left{
                display: flex;
                flex-direction: column;
                gap: 1rem;
                h4{
                    color: $black-0;
                    letter-spacing: .5px;
                }
            }
        }
        .footer {
            display: flex;
            gap: 5rem;
            margin-top: 4rem;
			padding-bottom: 3rem;
            .left{
                ul{
                    display: flex;
                    flex-direction: column;
                    li {
                        display: inline-block;
                        font-size: 16px;
                        letter-spacing: 1px;
                        color: $black-4;
                        font-weight: 600;
                    }
                }
            }
        }
	}
}
.return-pdf {
	padding: 40px;
	.devis {
	  width: 14.8125rem;
	  height: 5.3125rem;
	  border: 0.404px solid #cbd5e1;
	  background: #f6f6f6;
	  padding: 0.75rem;
	  h4 {
		color: #316161;
		font-weight: 700;
		line-height: normal;
	  }
	  .info {
		display: flex;
		gap: 4rem;
		label,
		span {
		  font-size: 0.5rem;
		  color: #316161;
		}
		div {
		  display: flex;
		  flex-direction: column;
		}
	  }
	}
	.general-info {
	  margin-top: 1.75rem;
	  display: flex;
	  gap: 6.7rem;
	  .left {
		width: 13rem;
		.infos-societe {
		  font-size: 0.875rem;
		  color: #316161;
		  margin-bottom: 1rem;
		}
		.address {
		  display: flex;
		  flex-direction: column;
		  gap: 0.75rem;
		  span {
			font-size: 0.625rem;
		  }
		}
	  }
	  .right {
		h3 {
		  color: #316161;
		  font-size: 0.875rem;
		  font-weight: 500;
		  line-height: 1rem;
		  margin-bottom: 1rem;
		}
		p {
		  color: #0f172a;
		  font-size: 0.625rem;
		  font-weight: 300;
		}
		span {
		  color: #0f172a;
		  font-size: 0.625rem;
		  font-weight: 300;
		}
	  }
	}
	.cher-client {
	  margin-top: 4.75rem;
	  p {
		font-size: 0.67rem;
	  }
	}
  
	.devis-table {
	  width: 100%;
	  margin-top: 3rem;
  
	  .offres {
		width: 100%;
		#titres {
		  width: 100%;
		  height: 2.5rem;
		  align-items: center;
		  font-weight: 600;
		  display: grid;
		  grid-template-columns: 25% 25% 25% 25% !important;
		  letter-spacing: 0.5px;
		  border: 0.404px solid #cbd5e1;
		  background: #f6f6f6;
		  margin-top: 3rem;
		}
		tbody {
		  .elements {
			width: 100%;
			height: 3.5rem;
			align-items: center;
			display: grid;
			grid-template-columns: 25% 25% 25% 25% !important;
			letter-spacing: 0.5px;
			border-bottom: 1px solid $gray-1-0;
  
			td {
			  color: $black-2;
			}
		  }
		}
		tr {
		  padding: 0.6rem;
		}
	  }
	  .page {
		position: fixed;
		bottom: 0;
		right: 12px;
		color: $green-1;
		font-size: 3rem;
		z-index: 555;
		font-weight: 600;
		padding: 1rem 40px;
		page-break-before: always;
	  }
	}
	.total-devis {
	  display: flex;
	  justify-content: flex-end;
	  margin-top: 1.75rem;
  
	  #box {
		width: 30%;
		gap: 1rem;
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;
  
		div {
		  width: 100%;
		  display: grid;
		  grid-template-columns: 40% 60%;
		  h1,
		  span {
			font-size: 12px;
			color: $black-2;
			font-weight: 600;
			text-align: left;
		  }
		  span {
			text-align: end;
		  }
		}
	  }
	}
	.livreur {
	  display: flex;
	  gap: 6.5rem;
	  margin-top: 14rem;
	  .lef {
		h4 {
		  color: $black-6;
		  font-size: 0.875rem;
		  font-weight: 500;
		  text-transform: uppercase;
		  margin-bottom: 1rem;
		}
		span {
		  color: $black-6;
		  font-size: 0.625rem;
		  font-style: normal;
		  font-weight: 300;
		  line-height: normal;
		}
	  }
	}
	.footer {
	  position: fixed;
	  bottom: 0;
	  left: 0;
	  right: 0;
	  display: flex;
	  gap: 6rem;
	  margin-top: 2.5rem;
	  background-color: $white;
	  padding: 1rem 40px;
	  .left {
		display: flex;
		flex-direction: column;
		gap: 0.5rem;
  
		li {
		  font-size: 0.5rem;
		  font-weight: 500;
		  color: $black-6;
		}
	  }
	}
  }