@use '../../../sass/' as *;

#catagory-cp{
    width: 100%;
	@media (max-width: 1300px) {
		width: 69rem;
	}

	.mainContent {
		.titres {
			display: grid;
			grid-template-columns: 20% 80%;
			height: 3rem;
		}
		tbody {
			.element {
				display: grid;
				grid-template-columns: 20% 80%;
				height: 5rem;
				#last{
					width: 80%;
					display: -webkit-box;
					-webkit-line-clamp:2;
					-webkit-box-orient: vertical;
					overflow: hidden;
					&:hover {
						-webkit-line-clamp: unset;
					}
				}
			}
		}
	}
}