@use '../../../sass/' as *;

#complainClientDetails {
  width: 100%;
  @media (max-width: 1300px) {
    width: 60rem;
  }
  #details-complain-header {
    @include header-page-add-see;
    .buttons {
      display: flex;
      gap: 1rem;
    }
  }
  h3 {
    color: #252525;
    font-family: Mukta;
    font-size: 1.125rem;
    margin-bottom: 0.5rem;
    margin-top: 2rem;
  }
  #headComplain {
    margin-top: 2em;
    width: 100%;
    .infos {
      margin-top: 0.4rem;
      div {
        display: flex;
        gap: 1rem;
      }
      h2 {
        font-size: 1.2rem;
        color: $green-1;
        width: 7rem;
      }
      span {
        width: 100%;
        display: inline-block;
        color: $black-6;
        font-size: 1.2rem;
      }
    }
  }
  label {
    width: fit-content;
  }
  .refus {
    width: 20px;
    height: 20px;
    color: white;
    font-size: 0.8rem;
    background-color:red;
    border-radius: 50%;
    display: grid;
    place-items: center;
    margin-left: 2px;
  }
}
