@use '../../../sass/' as *;

#pr-cart-cl {
	width: 100%;
	@media (max-width: 1300px) {
		width: 69rem;
	}
	.navbar {
		.right {
			justify-content: center;
			
			gap: 1rem;
			align-items: flex-end;
			.cart {
				position: relative;
				display: flex;
				.cart-icon {
					font-size: 3rem;
				}
				.cart-count {
					border-radius: 50%;
					background-color: $green-1;
					color: $white;
					width: 1.5rem;
					height: 1.5rem;
					@include flexcenter;
					position: absolute;
					left: 15px;
					span {
						display: block;
						font-weight: 600;
					}
				}
			}
			h3 {
				letter-spacing: 1px;
				color: $black-4;
				font-size: 1.3rem;
			}
		}
	}
    .modalcontainer{
		#confirmorderclient{
			top: 15%;
			left:50%;
			width: 35rem;
			height: 10rem;
			padding: 1rem;
			.field{
				padding-bottom: 1rem;
				h1{
					font-size: 1.3rem;
				}
				width: 100%;
			}
		}
        .modal{
            top:15%;
            width: 30rem;
            height: 7rem;
            flex-direction: column;
            padding: 1rem;
            gap: 0;
            .client-modal-order{
                display: flex;
                justify-content: center;
                h1{
                    font-size: 1.3rem;
                    color: $green-1;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                }
            }
        }
        // #failOrder{
        //     width: 40rem;
        // }
    }
	.content {
		margin-top: 2rem;
		width: 100%;
		.box {
            width: 70%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            button{
                width: 218px;
                height: 40px;
                font-size: 1rem;
                border: 2px solid #727272;
                color: #727272;
                background: $white-1;
                cursor: not-allowed;
                &.active {
                    cursor: pointer;
                    border: 2px solid $green-1;
                    color: $green-1;
                }
            }
        }
		.cart-section {
            display: grid;
            grid-template-columns: 70% 25%;
            column-gap: 5%;
			margin-top: 2rem;
			.purchases {
                width: 100%;
				.purchase {
                    padding: 1rem;
					width: 100%;
					border: 1px solid $gray-1;
					display: flex;
					justify-content: space-between;
                    background-color: $white;
					.left {
						width: 45%;
						.element {
							height: 100%;
							display: flex;
							gap: 1rem;
							.element-img {
								img {
									object-fit: contain;
									width: 5rem;
									height: 5rem;
								}
							}
							.element-desc {
								width: 60%;
								.price {
									color: $green-1;
									font-weight: 600;
									font-size: 1.1rem;
								}
							}
						}
					}
					.right {
						width: 50%;
						display: flex;
						justify-content: space-between;
						.confirmQTY {
                            display: flex;
                            justify-content: center;
                            button {
                                width: 6rem;
                                height: 2rem;
                                cursor: pointer;
                                border: 1px solid rgb(150, 150, 150);
                            }
                        }
						.qty {
							border: 1px solid $gray-1;
							width: 5rem;
							height: 2rem;
							input {
								font-size: 1.1rem;
								width: 100%;
								text-align: center;
							}
						}
						#qty-price {
							height: 2rem;
							width: 30%;
							text-align: center;
							span {
								color: $black-4;
								font-weight: 600;
								font-size: 1.1rem;
							}
						}
						.del {
							.trash {
								font-size: 1.3rem;
								color: $black-4;
							}
						}
					}
				}
			}
			.count {
				background-color: $white;
				border: 1px solid $gray-1;
				width: 100%;
				height: 17rem;
				padding: 1rem;
				@include flexcenter;
				flex-direction: column;
				gap: 1.5rem;
                letter-spacing: .5px;
				.count-header {
					width: 100%;
					label {
						color: $black-2;
						font-size: 1rem;
						font-weight: 500;
					}
					#shop-select {
						margin-top: .5rem;
						width: 100%;
						padding: .5rem .5rem;
						background-color: $white;
						border: $input-border;
						border-radius: $border-radius;
						select {
							width: 100%;
							background-color: $white;
							color: $gray-4;
							font-size: 16px;
							option {
								width: 90%;
								display: inline-block;
								font-size: 14px;
								color: $gray-4;
							}
						}
					}
				}
				.top {
					width: 100%;
					ul {
						list-style-type: disc;
						width: 70%;
						margin-left: 10%;
                        #number{
                            font-weight: bold;
                            color: $black-2;
                            span{

                                font-weight: bold;
                            }
                        }
						li {
							span {
								color: $black-2;
								font-size: 1rem;
								font-weight: 500;
							}
						}
					}
				}
				.bottom {
					width: 100%;
					button {
						width: 100%;
					}
				}
			}
		}
	}
	.return-link {
		a {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			gap: 1rem;
			color: $black-4;
		}
	}
}
