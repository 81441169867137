@use '../utilities/' as *;

.pagination {
    display: flex;
    justify-content: flex-end;
    margin-top: 2rem;
    gap: .8rem;
    width: 100%;
    margin-right: 1rem;
    @media (max-width: 1300px) {
        width: 69rem;
    }
    .arrows {
        gap: .6rem;
        display: flex;
        a{
            color: $black-6;
            text-decoration: none;
        }
        .arrow {
            
            cursor: pointer;
            font-size: 1.5rem;
        }
    }
}