@use "../../../sass/" as *;

#add-product {
	width: 100%;
	@media (max-width: 1300px) {
		width: 69rem;
	}
	padding-bottom: 5rem;
	.add-product-header {
		margin-bottom: 1.5rem;
	}
	form {
		width: 37rem;
	}
	.section {
		@include addInfos;
		.field {
			width: 90%;
		}
		#imgUpload {
			height: 15rem;
		}
	}
}
