.pinCodeCard{
    width:100%;
    display: inline;
    
    .pin{
        width: 15%;
        margin-left: 10%;
        text-align: center;
    }
}
