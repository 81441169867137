@use '../utilities/' as *;

.navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    gap: 5rem;
    width: 100%;
    .left {
        @include flexcenter;
        justify-content: flex-start !important;
        gap: 1rem;
        .filters {
            background-color: $white !important;
            height: 3rem;
            border-radius: $border-radius;
            @include flexcenter;
            box-shadow: 0px 0px 5px 1px $gray-1-0;
            min-width: 8rem;
            padding: 1rem;
            select {
                background-color: inherit;
                font-size: $font-size - .2rem;
                option {
                    padding: .5rem 1rem;
                    width: 100%;
                }
            }
        }
    }
    .right {
        @include flexcenter;
        justify-content: flex-end;
        padding-right: .5rem;
        .right-items {
            display: flex;
            gap: 1rem;
            .button-primary {
                gap: .5rem;
            }
            .button-nature {
                border: 1px solid $green-2;
                padding: 0rem 1rem !important;
                background-color: $white;
            }
        }
    }
}

#head {
    width: 100%;
    margin-top: 1rem;
  
    .title-user {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
}