@use '../../../sass/' as *;

#complaintsCl {
  width: 100%;
  @media (max-width: 1300px) {
    width: 69rem;
  }
  h1 {
    font-size: 1.7rem;
  }
  .modalcontainer {
    .modal {
      width: 30rem;
      flex-direction: column;
      height: 28rem;
      gap: 1rem;
      top: 35%;
      padding: 2rem;
      h1 {
        font-size: 1.4rem;
      }
      form {
        width: 100%;
        height: 100%;
        label {
          padding-block: 0.7rem;
          color: $black-6;
        }
        .box {
          .infodropdown {
            width: 100%;
            padding: 0.5rem 0.5rem;
            background-color: $white;
            border: $input-border;
            border-radius: $border-radius;
            select {
              width: 100%;
              background-color: $white;
              color: $gray-4;
              font-size: 16px;
              option {
                width: 90%;
                display: inline-block;
                font-size: 14px;
                color: $gray-4;
              }
            }
          }
        }
        .desc-modal {
          margin-bottom: 5%;
          height: 50%;
          textarea {
            height: 80%;
          }
        }
      }
    }
  }
  #complaintsContent {
    #complaints {
      width: 100%;
      border-top: $primary-border;
      border-bottom: $primary-border;
      #titres {
        display: grid;
        grid-template-columns: 20% 25% 20% 22% 13%;
        height: 2.5rem;
        align-items: center;
        font-weight: 600;
        td {
          font-size: 14px;
          text-align: left;
          letter-spacing: 1px;
        }
      }
      tbody {
        width: 100%;
        .complain {
          width: 100%;
          height: 3.5rem;
          display: grid;
          grid-template-columns: 20% 25% 20% 22% 13%;
          align-items: center;
          align-content: center;
          border-top: $primary-border;
          letter-spacing: 0.5px;
          font-size: 0.9rem;
          #object,
          .object {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            line-height: 1em;
            width: 80%;
            height: 50%;
          }
          .last-complain-element {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50%;
            height: 100%;
            gap: 1rem;
            .see-complain {
              cursor: pointer;
              font-size: 1.1rem;
              color: $black-0;
            }
          }
          .status {
            border-radius: 36px;
            width: 5rem;
            text-align: center;
            padding: 0.3rem 0rem;
            font-weight: 500;
            letter-spacing: 0.5px;
            color: $orange-1;
            background-color: $orange-2;
            &.traité {
              color: $green-3-1;
              background-color: $green-3-0;
            }
          }
        }
      }
    }
  }
}
