@use '../../../sass' as *;

#update-cp {
  width: 100%;
  @media (max-width: 1300px) {
    width: 69rem;
  }

  form,.vehicule{
    width: 37rem;
    h2,
    h3 {
      color: $black-6;
    }
    label {
      margin-bottom: 0.3rem;
    }
    input[type="button"] {
      background-color: $green-1;
      color: $white;
      cursor: pointer;
      border: none;
    }
    input.vehicule {
      width: 100%;
    }
    .add-shop-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .user-infos {
      background-color: $white;
      padding: 1rem;
      box-shadow: $box-shadow;
      border: $primary-border;
      border-radius: $border-radius;
      margin-bottom: 1.3rem;

      .form-line {
        margin-top: 0.8rem;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        width: 90%;

        .frag {
          width: 48%;
          display: flex;
          flex-direction: column;
        }
      }
      .field {
        width: 90%;
      }
    }
    .add-shop-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .shop {
      padding: 1rem;
      box-shadow: $box-shadow;
      border: $primary-border;
      border-radius: $border-radius;
      margin-bottom: 1.3rem;
      background-color: $white;
      .del-shop {
        display: flex;
        justify-content: flex-end;
        .box-bordered {
          height: 2rem;
          width: 2rem;
          @include flexcenter;
          background-color: $blue-2;
          cursor: pointer;
          // border: 1px solid $black-5;
          border-radius: 50%;
          .del-shop-icon {
            font-size: 1.2rem;
            color: $black-5;
          }
        }
      }
      label {
        margin-bottom: 0.3rem;
      }
      input {
        width: 90%;
        margin-bottom: 1.3rem;
      }
      input[type="button"] {
        background-color: $green-1;
        color: $white;
        cursor: pointer;
        border: none;
      }
      input.vehicule {
        width: 100%;
      }
      .delivery-details {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        label {
          margin-bottom: 0;
        }
        .info-dropdown {
          margin-top: 0;
        }
        .info-1 {
          display: flex;
          justify-content: space-between;
          width: 90%;
          // margin-bottom: .4rem;
          input {
            width: 48%;
          }
        }
      }
    }
    label {
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 160%;
      letter-spacing: 1px;
    color: #1E293B;
    }
    input {
      padding: 10px;
      border-radius: 0.125rem;
      border: 1px solid #cbd5e1;
      background: #fff;
    }
  }
  .vehicule-btns {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    .confirmer-vehicule {
      border-radius: 0.125rem;
      border: 1px solid #316161;
      background: #dee;
      color: #316161;
    }
    .annuler-vehicule {
      box-shadow: none;
    }
  }
}
