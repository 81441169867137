// -----------------------------------------------------------------------------
// This file contains all styles related to the side bar of the site/application.
// -----------------------------------------------------------------------------

@use '../utilities/' as *;
.sidebar {
	width: 16rem;
	height: 100%;
	position: fixed;
	left: 0;
	top: 0;
	background-color: $green-1;
	z-index: 1000;
	.content {
		#logoBox {
			padding: 1rem 24px;
		}

		#nav {
			list-style: none;

			.navItem {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				height: 48px;
				gap: 16px;
				margin-top: .4rem;
				cursor: pointer;
				padding-left: 30px;
				:nth-child(1) {
					color: $white;
					width: 16px;
					height: 16px;
				}
				span {
					font-size: $font-size;
					font-weight: 400;
					color: $white;
					text-transform: none;
				}
				&.active {
					background-color: $white;
					border-right: 3px solid $color-tertiary;
					:nth-child(1) {
						color: $green-2;
					}
					span {
						color: $green-2;
					}
				}
			}
			// a{

				.logout {
					color: $warning;
					display: flex;
					justify-content: flex-start;
					align-items: center;
					height: 2rem;
					gap: 16px;
					margin-top: .4rem;
					cursor: pointer;
					padding-left: 30px;
					font-size: $font-size;
					font-weight: 400;
					text-transform: none;
				}
			// }
		}
	}
	.hide{
		display: none;
	}
}
