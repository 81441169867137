@use '../../../sass/' as *;

#billPageAdmin {
  .title-user h1 {
    font-size: 1.7rem;
  }
  #billsContent {
    width: 100%;
    @media (max-width: 1300px) {
      width: 69rem;
    }
    #bills {
      width: 100%;
      border-top: 1px solid $gray-1-0;
      border-bottom: 1px solid $gray-1-0;
      #titres {
        width: 100%;
        display: grid;
        grid-template-columns: 5% 15% 15% 20% 23% 14% 8%;

        height: 2.5rem;
        align-items: center;
        font-weight: 600;
        td {
          font-size: 14px;
          text-align: left;
          letter-spacing: 1px;
        }
      }
      tbody {
        width: 100%;
        .delivery {
          height: 3rem;
          border-top: 1px solid $gray-1-0;
          display: grid;
          align-items: center;
          grid-template-columns: 5% 15% 15% 20% 23% 14% 8%;
          letter-spacing: 0.5px;
          font-size: $font-size - 0.3rem;
          .last-delivery-element {
            display: flex;
            align-items: center;
            height: 100%;
            gap: 1rem;
            width: 50%;
            margin-left: 1.2rem;
            .link-delivery {
              display: flex;
            }
            .see-delivery,
            .delivery-option {
              cursor: pointer;
              font-size: 1.2rem;
              color: $black-0;
            }
          }
          td:nth-child(-n + 5) { 
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 250px;
            transition: 0.1s all ease-in-out;
          }
          td {
            font-size: 14px;
          }
          .contain {
            display: inline-block;
            position: relative;
            @include flexcenter;
          }
        }
      }
    }
  }
}
