@use '../../../sass/' as *;

#updateOrder {
  width: 100%;
  .update-order-header {
    @include header-page-add-see;
  }
  .modalcontainer {
    .modal {
      width: 37rem;
      height: 10rem;
      flex-direction: column;
      justify-content: center;
      padding: 1rem;
      gap: 0;
      .client-modal-order {
        display: flex;
        justify-content: center;
        margin-bottom: 2.5rem;
        h1 {
          font-size: 1.3rem;
          text-align: center;
          width: 100%;
        }
      }
      .modalbtn {
        gap: 1rem;
        margin-left: 5rem;
        justify-content: center;
      }
    }
  }
  .container {
    margin-top: 2rem;
    width: 100%;
    @media (max-width: 1300px) {
      width: 63rem;
    }
    .infos-societe {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      label:nth-child(1) {
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        color: $black-6;
      }
      label:nth-child(2),
      label:nth-child(3) {
        text-transform: uppercase;
        color: $black-6;
        font-family: Mukta;
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
      }
    }
    .credentials {
      margin-top: 2rem;
      display: flex;
      gap: 3rem;
      width: 100%;
      .left {
        display: flex;
        flex-direction: column;
        border: $primary-border;
        border-radius: $border-radius;
        padding: 1rem;
        width: 40%;
        .left-el {
          display: flex;
          flex-direction: column;
          margin-bottom: 0.5rem;
          label {
            color: $green-1;
            display: inline-block;
            font-size: 18px;
            font-weight: 600;
            letter-spacing: 1px;
          }
          input {
            text-align: center;
            width: 50%;
            font-weight: normal;
            color: $black-0;
          }
        }
        .total {
          display: flex;
          flex-direction: column;
          label {
            font-size: 1rem;
            font-weight: 700;
            color: $green-1;
          }
        }
      }
      .right {
        @extend .left;
        label {
          color: $green-1;
          display: inline-block;
          font-size: 18px;
          font-weight: 600;
          letter-spacing: 1px;
        }
        span {
          color: $gray-3;
        }
      }
    }
    .devis {
      p {
        color: $black-4;
        margin-top: 3rem;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0.5px;
      }
      .devis-head {
        margin-top: 2rem;
        .total-devis {
          display: flex;
          .total-devis-line {
            gap: 1rem;
            display: flex;
            margin-top: 0.5rem;
            display: flex;
            align-items: center;
            justify-content: space-between;

            h1 {
              font-size: 1.2rem;
              font-weight: 600;
              color: $black-0;
            }
            .info-dropdown {
              width: 30% !important;
              transform: translateY(-8px);
              select {
                color: $black-0;
                option {
                  color: inherit;
                }
              }
            }
            input {
              color: $black-0;
              width: 30%;
            }
          }
        }
        #devis-name-order-admin {
          color: $black-0;
          border-bottom: 4px solid $green-1;
        }
      }
      .devis-table {
        width: 100%;
        margin-top: 6rem;
        .offres {
          width: 100%;
          border-top: 1px solid $gray-1-0;
          border-bottom: 1px solid $gray-1-0;

          #titres {
            width: 100%;
            height: 2.5rem;
            align-items: center;
            font-weight: 600;
            display: grid;
            grid-template-columns: 15% 15% 25% 9% 7% 10% 10% 9%;
            letter-spacing: 0.5px;
          }
          tbody {
            .elements {
              width: 100%;
              height: 3.5rem;
              align-items: center;
              display: grid;
              grid-template-columns: 15% 15% 25% 9% 7% 10% 10% 9%;
              letter-spacing: 0.5px;
              border-top: 1px solid $gray-1-0;
              td {
                input {
                  width: 80%;
                  background-color: $white;
                  color: $black-0;
                }
              }
            }
          }
        }

        .total-devis-table {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          margin-top: 4rem;
          margin-left: 10%;
          #box {
            width: 40%;
            gap: 1rem;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            div {
              width: 100%;
              display: grid;
              grid-template-columns: 40% 60%;
              gap: 1rem;
              h1,
              span {
                font-size: 1.2rem;
                color: $black-2;
                font-weight: 600;
                text-align: left;
              }
            }
          }
        }
      }
      .message {
        margin-top: 5rem;
        border-bottom: 1px solid $gray-1-0;
        padding-bottom: 2rem;
      }
      .footer {
        display: flex;
        gap: 4rem;
        margin-top: 3rem;
        padding-bottom: 3rem;
        .left {
          ul {
            display: flex;
            flex-direction: column;
            li {
              display: inline-block;
              font-size: 16px;
              letter-spacing: 1px;
              color: $black-4;
              font-weight: 600;
            }
          }
        }
      }
    }
  }
}
