@use '../../../sass/' as *;

#updateProfile {
  width: 100%;
  @media (max-width: 1300px) {
    width: 69rem;
  }
  #updateHeader {
    @include header-page-add-see;
    h1 {
      font-size: 2rem;
    }
  }
  form {
    width: 60%;
    padding-bottom: 3rem;
    .add-btns {
      display: flex;
      gap: 2rem;
    }
    .label-input {
      margin-top: 1rem;
      input {
        width: 90%;
        margin-top: 0.5rem;
      }
    }
    .submit-btns {
      padding-top: 2rem;
      width: 90%;
    }
  }
}
