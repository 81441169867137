@use "../../../sass/" as *;

#admin-list {
	width: 100%;
	@media (max-width: 1300px) {
		width: 65rem;
	}
	.mainContent {
		.titres {
			display: grid;
			grid-template-columns: 25% 25% 22% 22% 6%;
			// td:not(td:nth-child(1)) {
			// 	margin: auto;
			// }
		}
		tbody {
			.element {
				display: grid;
				grid-template-columns: 25% 25% 22% 22% 6%;
				// td:not(td:nth-child(1)) {
				// 	margin: auto;
				// }
				.last-element {
					width: 50%;
					justify-content: center;
					.contain {
						width: 100%;
						.option {
							cursor: pointer;
							color: $black-6;
						}
					}
				}
			}
		}
	}

	.modalcontainer {
		.modal {
			width: 34rem;
			height: 40rem;
			padding: 2rem;
			flex-direction: column;
			justify-content: center;
			overflow: visible;
			gap: 1rem;
			top: 50%;

			form {
				flex-direction: column;
				height: 100%;
				margin-top: 0rem;
				padding: 0;
				.label {
					margin-bottom: 0.2rem;
				}
				.label-input {
					margin-top: 1rem;
					position: relative;
					.see-password {
						cursor: pointer;
						position: absolute;
						right: 1rem;
						bottom: 0.8rem;
						color: $gray-3;
					}
				}
				.line-admin {
					display: grid;
					grid-template-columns: repeat(2, 47%);
					column-gap: 6%;
				}
				.modalbtn {
					margin-top: 2rem;
				}
			}
		}
		#updateAdmin {
			@extend .modal;
			height: 35rem;
		}
	}
}
