@use "../../sass/" as *;

.card {
    width: 100%;
    background-color: $white;
    box-shadow: $box-shadow;
    display: flex;
    flex-direction: column;
    height: 15rem;
    .top {
        padding: 0rem 1rem;
        @include flexcenter;
        position: relative;
        height: 65%;
        img {
            width: 100%;
            height: 100%;
            z-index: 2;
            object-fit: contain;
        }
        .cart-actions {
            z-index: 4;
            width: fit-content;
            height: 50px;
            display: flex;
            gap: 12px;
            align-items: flex-end;
            position: absolute;
            bottom: 1rem;
        }
        .shop {
            border-radius: 50%;
            background-color: $green-1;
            opacity: 71%;
            color: $white;
            width: 2rem;
            height: 2rem;
            @include flexcenter;
            z-index: 4;
            cursor: pointer;
            &:hover {
                background-color: $green-1;
                opacity: 100%;
            }
            &.disabled {
                cursor: not-allowed ;
            }
        }
        .eye {
            @extend .shop;
            cursor: pointer;
            background-color: $green-1;
            &.disabled {
                cursor: pointer;
                background-color: #EF4444;   
            }
        }
    }
    .bottom {
        height: 35%;
        padding: 0.5rem 1rem;
        border-top: 2px solid $gray-1-1;
        width: 100%;
        @include flexcenter;
        flex-direction: column;
        gap: 0.7rem;
        h3 {
            font-size: 16px;
            text-align: center;
            height: 50%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 160px;
            transition: 0.1s all ease-in-out;
        }
        span {
            height: 50%;
            color: $green-1;
            font-size: 1.1rem;
            font-weight: 600;
            width: max-content;
            text-align: center;
        }
    }
}
