@use "../../../sass/" as *;

#deliveryPag {
  #head {
    h1 {
      font-size: 1.7rem;
    }
  }
  #deliveriesContent {
    width: 100%;
    @media (max-width: 1300px) {
      width: 69rem;
    }
    #deliveries {
      width: 100%;
      border-top: 1px solid $gray-1-0;
      border-bottom: 1px solid $gray-1-0;
      #titres {
        width: 100%;
        display: grid;
        grid-template-columns: 3% 8% 10% 15% 12% 12% 8% 10% 15% 8% !important;
        height: 2.5rem;
        align-items: center;
        font-weight: 600;
        td {
          font-size: 14px;
          text-align: left;
          letter-spacing: 1px;
          &.center {
            margin: auto;
          }
        }
      }
      tbody {
        width: 100%;
        .delivery {
          height: 3rem;
          border-top: 1px solid $gray-1-0;
          display: grid;
          align-items: center;
          grid-template-columns: 3% 8% 10% 15% 12% 12% 8% 10% 15% 8% !important;
          letter-spacing: 0.5px;
          font-size: $font-size - 0.3rem;
          .last-delivery-element {
            display: flex;
            align-items: center;
            height: 100%;
            gap: 1rem;
            .link-delivery {
              display: flex;
            }
            .see-delivery,
            .delivery-option {
              cursor: pointer;
              font-size: 1.2rem;
              color: $black-0;
            }
          }
          .contain {
            display: inline-block;
            position: relative;
            @include flexcenter;
          }
          td:nth-child(-n + 6) { 
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 160px;
            transition: 0.1s all ease-in-out;
          }
          .box {
            display: flex;
            gap: 4px;
            img {
              display: none;
              &.rotate {
                cursor: pointer;
                display: block;
              }
            }
          }
          .commercial-status {
            border-radius: 36px;
            width: 6rem;
            text-align: center !important;
            padding: 0.3rem 0rem;
            font-weight: 500;
            letter-spacing: 0.5px;
            margin: auto;
            &.en-cours {
              background: #feeee2;
              color: #ef8e44;
            }
            &.accepter {
              background: #d1dffa;
              color: #104bb9;
            }
            &.refuser {
              background: $orange-3;
              color: $danger;
            }
          }
          .delivery-status {
            border-radius: 36px;
            width: 6rem;
            text-align: center !important;
            padding: 0.3rem 0rem;
            font-weight: 500;
            letter-spacing: 0.5px;
            &.en-cours {
              background-color: $orange-2;
              color: $orange-1;
            }

            &.a-confirmer {
              background: #f2d1fa;
              color: #9a10b9;
            }

            &.confirmer {
              background-color: $green-3-0;
              color: $green-3-1;
            }

            &.refuser {
              background: $orange-3;
              color: $danger;
            }
          }
        }
      }
    }
  }
}
