@use'../../../sass/' as *;

.container {
	width: 100%;
	* {
		text-transform: none;
	}

	.box {
		display: flex;
		height: 100vh;
		justify-content: center;
		.left {
			width: 50%;
			height: 100%;
			background-color: $white-2;
			position: relative;
			display: flex;
			flex-direction: column;
			padding: 2% 5%;
			justify-content: space-between;

			@media (max-width: 768px) {
				width: 100%;
				height: 100vh;
			}
			.header {
				display: flex;
				flex-direction: column;
				width: 100%;
				span {
					width: 100%;
					display: inline-block;
					color: $gray-4-1;
					text-transform: none;
				}
			}
			.form {
				width: 100%;
				display: flex;
				flex-direction: column;
				justify-content: center;
				position: relative;

				label {
					margin-top: 1rem;
					font-size: $font-size;
					padding-bottom: 1rem;
				}
				input {
					border-radius: 10px;
					padding: 1rem;
					border: 1px solid $white;
					font-size: $font-size;
					caret-color: $green-2;
					&:focus {
						border-color: $green-2;
					}
				}
				.btn {
					background-color: $green-2;
					border-radius: 10px;
					padding: 1rem;
					font-size: $font-size;
					margin-top: 5%;
					color: $white;
          cursor: pointer;
				}
			}
			.footer {
				width: 100%;

				justify-content: flex-start;

				@media (max-width: 768px) {
					width: 100%;
				}
			}
		}

		.right {
			width: 50%;
			height: 100%;
			@media (max-width: 768px) {
			  display: none;
			}
			img {
				object-fit: cover;
				height: 100%;
				width: 100%;
			}
		}
	}
}
