@use '../../../sass/' as *;

#clientListPage {
  width: 100%;
  @media (max-width: 1300px) {
    width: 69rem;
  }

  .usersContent {
    width: 100%;
    #users {
      width: 100%;
      border-top: 1px solid $gray-1-0;
      border-bottom: 1px solid $gray-1-0;

      #titres {
        width: 100%;
        display: grid;
        grid-template-columns: 3% 20% 15% 18% 13% 10% 13% 7%;

        height: 2.5rem;
        align-items: center;
        font-weight: 600;
        td {
          font-size: 14px;
          text-align: left;
          letter-spacing: 1px;
        }
      }
      tbody {
        width: 100%;
        .user {
          z-index: 3;
          height: 3.5rem;
          border-top: 1px solid $gray-1-0;
          display: grid;
          align-items: center;
          letter-spacing: 0.5px;
          grid-template-columns: 3% 20% 15% 18% 13% 10% 13% 7%;
          td:nth-child(-n + 3) {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 200px;
            transition: 0.1s all ease-in-out;
          }
          .last-user-element {
            display: flex;
            align-items: center;
            height: 100%;
            gap: 1rem;
            .link-user {
              display: flex;
              // justify-content: center;
              // width: 50%;
            }
            .see-user,
            .user-option {
              cursor: pointer;
              font-size: 1.2rem;
              color: $black-0;
            }
          }
          .user-status {
            border-radius: 36px;
            width: 5rem;
            color: $green-3-1;
            background-color: $green-3-0;
            text-align: center !important;
            padding: 0.3rem 0rem;
            font-weight: 500;
            letter-spacing: 0.5px;
            &.activé {
              color: $danger;
              background-color: $orange-3;
            }
          }
          .contain {
            display: inline-block;
            position: relative;
            @include flexcenter;
          }
        }
      }
    }
  }
  .user-pagination {
    display: flex;
    justify-content: flex-end;
    margin-top: 2rem;
    gap: 0.8rem;
    @media (max-width: 1300px) {
      width: 69rem;
    }
    .arrows {
      gap: 0.6rem;
      display: flex;
      .arrow {
        cursor: pointer;
        font-size: 1.5rem;
      }
    }
  }
}
