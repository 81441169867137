@use '../../../sass/' as *;

.container {
  overflow: hidden;
  width: 100%;
  * {
    text-transform: none;
  }

  .box {
    display: flex;
    height: 100vh;
    justify-content: center;
    .left {
      width: 50%;
      height: 100%;
      background-color: $white-2;
      position: relative;
      display: flex;
      flex-direction: column;
      padding: 2% 5%;
      justify-content: space-between;

      @media (max-width: 768px) {
        width: 100%;
        height: 100vh;
      }
      .header {
        display: flex;
        flex-direction: column;
        width: 100%;
        span {
          width: 100%;
          display: inline-block;
          color: $gray-4-1;
          text-transform: none;
        }
      }
      .form {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;

        label {
          margin-top: 1rem;
          font-size: $font-size;
        }
        input {
          border-radius: 10px;
          padding: 1rem;
          border: 1px solid $white;
          font-size: $font-size;
          caret-color: $green-2;
          &:focus {
            border-color: $green-2;
          }
        }
        .label-input {
          margin-top: 1rem;
          position: relative;
          .see-password {
            cursor: pointer;
            position: absolute;
            right: 1rem;
            bottom: 21px;
            font-size: 19px;
            color: $gray-3;
          }
        }
        span {
          color: red;
          font-size: 15px;
          padding: 7px 0 0;
        }
        .resetPassword {
          color: $green-2;
          text-decoration: none;
          text-align: end;
          padding-top: 20px;
        }
        .btn {
          background-color: $green-1;
          border-radius: 10px;
          padding: 1rem;
          font-size: $font-size;
          margin-top: 10%;
          color: $white;
          cursor: pointer;
          @include flexcenter;

          svg {
            width: 2rem;
            height: 2rem;
          }
        }
      }
      .footer {
        width: 100%;

        justify-content: flex-start;

        @media (max-width: 768px) {
          width: 100%;
        }
      }
    }

    .right {
      width: 50%;
      height: 100%;
      @media (max-width: 768px) {
        display: none;
      }
      img {
        object-fit: cover;
        height: 100%;
        width: 100%;
      }
    }
  }
}
