@use'../utilities/' as *;

h1{
    font-weight: 700;
    color: $black-6;
    letter-spacing: 1px;
    line-height: normal;
    display: inline-block;
}
h2{
    color: $black-4;
    line-height: normal;
}
h3{
    color: $black-4;
    line-height: normal;
}
h4{
    color: $black-4;
    line-height: normal;
}
ul{
    list-style: none;
}
a{
    text-decoration: none;
}
td{
line-height: normal;
}

