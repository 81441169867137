@use '../../../sass/' as *;

#orderCart {
  width: 100%;
  @media (max-width: 1300px) {
    width: 69rem;
  }
  .navbar {
    .right {
      justify-content: center;
      @media (max-width: 1300px) {
        justify-content: flex-end;
      }
      gap: 1rem;
      align-items: flex-end;
      .cart {
        position: relative;
        display: flex;
        .cart-icon {
          font-size: 3rem;
        }
        .cart-count {
          border-radius: 50%;
          background-color: $green-1;
          color: $white;
          width: 1.5rem;
          height: 1.5rem;
          @include flexcenter;
          position: absolute;
          left: 15px;
          span {
            display: block;
            font-weight: 600;
          }
        }
      }
      h3 {
        letter-spacing: 1px;
        color: $black-4;
        font-size: 1.3rem;
      }
    }
  }
  .content {
    margin-top: 2rem;
    width: 100%;
    .cart-section {
      display: grid;
      grid-template-columns: 70% 25%;
      column-gap: 5%;
      margin-top: 2rem;
      .purchases {
        width: 100%;
        .purchase {
          padding: 1rem;
          width: 100%;
          height: 7rem;
          display: flex;
          justify-content: space-between;
          border: 1px solid $gray-1;
          background-color: $white;
          .left {
            width: 45%;
            .element {
              height: 100%;
              display: flex;
              gap: 1rem;
              .element-img {
                img {
                  width: 5rem;
                  height: 5rem;
                }
              }
              .element-desc {
                width: 60%;
                .price {
                  color: $green-1;
                  font-weight: 600;
                  font-size: 1.1rem;
                }
              }
            }
          }
          .right {
            width: 50%;
            display: flex;
            justify-content: space-between;
            .qty {
              display: flex;
              border: 1px solid $gray-1;
              width: 15%;
              height: 2rem;
              span {
                font-size: 1.1rem;
                width: 70%;
                text-align: center;
              }
              .modifyQty {
                width: 30%;
                height: 100%;
                display: flex;
                flex-direction: column;
                gap: 1px;
                span {
                  height: 50%;
                  width: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-size: 1.2rem;
                  cursor: pointer;
                  color: $green-1;
                  border-left: 1px solid $gray-1;
                  border-bottom: 1px solid $gray-1;
                }
              }
            }
            #qty-price {
              height: 2rem;
              width: 30%;
              text-align: center;
              span {
                display: inline;
                color: $black-4;
                font-weight: 600;
                font-size: 1.1rem;
              }
            }
            .del {
              .trash {
                font-size: 1.3rem;
                color: $black-4;
              }
            }
          }
        }
      }
      .count {
        background-color: $white;
        border: 1px solid $gray-1;
        width: 100%;
        height: 17rem;
        padding: 1rem;
        @include flexcenter;
        flex-direction: column;
        gap: 1.5rem;
        letter-spacing: 0.5px;
        .count-header {
          width: 100%;
          label {
            color: $black-2;
            font-size: 1rem;
            font-weight: 500;
          }
          #shop-select {
            margin-top: 0.5rem;
            width: 100%;
            padding: 0.5rem 0.5rem;
            background-color: $white;
            border: $input-border;
            border-radius: $border-radius;
            select {
              width: 100%;
              background-color: $white;
              color: $gray-4;
              font-size: 16px;
              option {
                width: 90%;
                display: inline-block;
                font-size: 14px;
                color: $gray-4;
              }
            }
          }
        }
        .top {
          width: 100%;
          ul {
            list-style-type: disc;
            width: 70%;
            margin-left: 10%;
            #number {
              font-weight: bold;
              color: $black-2;
              span {
                font-weight: bold;
              }
            }
            li {
              span {
                color: $black-2;
                font-size: 1rem;
                font-weight: 500;
              }
            }
          }
        }
        .bottom {
          width: 100%;
          button {
            width: 100%;
          }
        }
      }
    }
  }
  .link-return {
    a {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 0.5rem;
      color: $black-4;
    }
  }
}
