@use '../../../sass/' as *;

#pr-page-client{
    width: 100%;
	@media (max-width: 1300px) {
		width: 69rem;
	}
    .navbar {
		.right {
			justify-content: center;
			@media (max-width: 1300px) {
				justify-content: flex-end;
			}
			gap: 1rem;
			align-items: flex-end;
			.cart {
				position: relative;
				display: flex;
				.cart-icon {
					font-size: 3rem;
				}
				.cart-count {
					border-radius: 50%;
					background-color: $green-1;
					color: $white;
					width: 1.5rem;
					height: 1.5rem;
					@include flexcenter;
					position: absolute;
					left: 15px;
					span {
						display: block;
						font-weight: 600;
					}
				}
			}
			h3 {
				letter-spacing: 1px;
				color: $black-4;
				font-size: 1.3rem;
			}
		}
	}
	#cartContent {
		display: grid;
        grid-template-columns: repeat(auto-fill, 14rem);
		justify-content: center;
		gap: 1rem;
		width: 100%;
		.card {
			height: 18rem;
			background-color: $white;
			box-shadow: $box-shadow;
			display: flex;
			flex-direction: column;
			.top {
				padding: 0rem 1rem;
				@include flexcenter;
				position: relative;
				height: 70%;
				img {
					width: 100%;
					height: 100%;
					z-index: 2;
				}
				.cart-cart {
					border-radius: 50%;
					background-color: $blue-sky;
					color: $white;
					width: 2rem;
					height: 2rem;
					@include flexcenter;
					position: absolute;
					bottom: .7rem;
					left: 35%;
					z-index: 4;
					cursor: pointer;
					&:hover {
						background-color: $green-1;
					}
				}
				.cart-eye {
					@extend .cart-cart;
					left: 45%;
					cursor: pointer;
				}
			}
			.bottom {
				height: 30%;
				padding: .5rem 1rem;
				border-top: 2px solid $gray-1-1;
				width: 100%;
				@include flexcenter;
				flex-direction: column;
				gap: .7rem;
				h3 {
					font-size: 16px;
					width: 100%;
					text-align: center;
				}
				span {
					color: $green-1;
					font-size: 1.1rem;
					font-weight: 600;
					width: 100%;
					text-align: center;
				}
			}
		}
	}
}
.modalcontainer {
	box-shadow: none;
	.modal {
		background-color: $white;
		height: 32rem;
		width: 70rem;
		padding: 3rem;
		border-radius: 4px;
		border: 1px solid $gray-1-1;
		flex-direction: row;
		display: none;
		left: 12%;
		gap: 3rem;

		.close-modal {
			display: flex;
			justify-content: flex-end;
            cursor: pointer;
            :nth-child(1){
                font-size: 1.3rem;
            }
		}
		.left-modal {
			width: 35%;
			height: 26rem;
			border: 1px solid $gray-1;
			border-radius: $border-radius;
			img {
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
		}
		.right-modal {
			height: 100%;
			width: 65%;
			display: flex;
			flex-direction: column;
			gap: .7rem;
            #price{
                color: $green-1;
                font-weight: 600;
                font-size: 1.1rem;
            }
            p{
                color: $black-4;
            }
			.line-desc {
				width: 50%;
				display: flex;
				justify-content: space-between;
                align-items: center;
				input {
                    width: 8rem;
					display: inline-block;
					font-size: 16px;
					letter-spacing: 1px;
					padding: .5rem 1rem;
					border-radius: $border-radius;
					border: 1px solid $gray-1;
					color: $gray-4;
				}
			}
            .btn{
                margin-top: .8rem;
                margin-bottom: 1rem;
                button{
                    gap: .5rem;
                }
            }
		}
		&.active {
			display: flex;
		}
	}
	&.open {
		position: fixed;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		z-index: 1000;
		@include flexcenter;
		transition: background-color 0.15s ease-out;
		background-color: rgba(0, 0, 0, 0.75);
	}
}