@use '../../../sass/' as *;

#returnPage {
  width: 100%;
  @media (max-width: 1300px) {
    width: 69rem;
  }
  .title-user h1 {
    font-size: 1.7rem;
  }
  #returnsContent {
    width: 100%;
    @media (max-width: 1300px) {
      width: 69rem;
    }
    #returns {
      width: 100%;
      border-top: 1px solid $gray-1-0;
      border-bottom: 1px solid $gray-1-0;
      .center {
        margin: auto;
      }
      #titres {
        width: 100%;
        display: grid;
        grid-template-columns: 5% 15% 20% 14% 10% 20% 16%;
        height: 2.5rem;
        align-items: center;
        font-weight: 600;
        td {
          font-size: 14px;
          text-align: left;
          letter-spacing: 1px;
        }
      }
      tbody {
        width: 100%;
        .return {
          height: 3rem;
          border-top: 1px solid $gray-1-0;
          display: grid;
          align-items: center;
          grid-template-columns: 5% 15% 20% 14% 10% 20% 16%;
          letter-spacing: 0.5px;
          font-size: $font-size - 0.3rem;
          .last-return-element {
            display: flex;
            align-items: center;
            height: 100%;
            gap: 1rem;
            .link-return {
              display: flex;
            }
            .see-return,
            .return-option {
              cursor: pointer;
              font-size: 1.2rem;
              color: $black-0;
            }
          }
          td:nth-child(-n + 5) { 
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 250px;
            transition: 0.1s all ease-in-out;
          }
          .status {
            border-radius: 36px;
            width: 5rem;
            text-align: center;
            padding: 0.3rem 0rem;
            font-weight: 500;
            letter-spacing: 0.5px;
            color: $orange-1;
            background-color: $orange-2;
            text-transform: capitalize;
            &.traité {
              color: $green-3-1;
              background-color: $green-3-0;
            }
          }
          .contain {
            display: inline-block;
            position: relative;
            @include flexcenter;
          }
        }
      }
    }
  }
}
