@use "../../../sass/" as *;

#categoryPage {
  width: 100%;
  @media (max-width: 1300px) {
    width: 69rem;
  }
  .modalcontainer {
    .modal {
      height: 30rem;
      width: 35rem;
      padding: 2rem 2rem;
      flex-direction: column;
      gap: 1rem;
      top: 40%;
      form {
        margin-top: 1rem;
        gap: 1rem;
        .submit-btns {
          width: inherit;
        }
      }
      &.active {
        display: flex;
      }
    }
    &.open {
      position: fixed;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      z-index: 1000;
      @include flexcenter;
      transition: background-color 0.15s ease-out;
      background-color: rgba(0, 0, 0, 0.75);
    }
  }

  #categoriesContent {
    width: 100%;
    #categories {
      width: 100%;
      border-top: 1px solid $gray-1-0;
      border-bottom: 1px solid $gray-1-0;
      #titres {
        width: 100%;
        display: grid;
        grid-template-columns: 5% 20% 65% 10%;
        padding: 1rem;
        height: 2.5rem;
        align-items: center;
        align-content: center;
        font-weight: 600;
        td {
          font-size: 14px;
          text-align: left;
          letter-spacing: 1px;
        }
      }
      tbody {
        width: 100%;
        .product {
          padding: 1rem;
          min-height: 3rem;
          border-top: 1px solid $gray-1-0;
          display: grid;
          align-items: center;
          letter-spacing: 0.5px;
          grid-template-columns: 5% 20% 65% 10%;
          font-size: $font-size - 0.3rem;
          .input-check {
            display: flex;
          }
          .last-user-element {
			      width: 50%;
            display: grid;
            grid-template-columns: 30% 30%;
            align-items: center;
            gap: 1rem;
            .edit-cat,
            .del-cat {
              cursor: pointer;
              font-size: 1.2rem;
              color: $black-0;
            }
          }
          #category-desc {
            width: 85%;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;    
            overflow: hidden;        
            transition: -webkit-line-clamp 0.5s ease;
            &:hover {
              -webkit-line-clamp: unset;
            }
          }
          &.active {
            background-color: $gray-4-0;
            border: none;
          }
        }
      }
    }
  }
  .title-user{
    @include header-page-add-see;
  }
}
