@use '../utilities/' as *;

.mainContent {
	width: 100%;

	.content {
		width: 100%;
		border-top: 1px solid $gray-1-0;
		border-bottom: 1px solid $gray-1-0;

		.titres {
			width: 100%;
			height: 2.5rem;
			align-items: center;
			font-weight: 600;
			td {
				font-size: 1rem;
				text-align: left;
				letter-spacing: 1px;
				color: $black-2;
			}
		}
		tbody {
			width: 100%;
			.element {
				z-index: 3;
				height: 3.5rem;
				border-top: 1px solid $gray-1-0;
				align-items: center;
				letter-spacing: .5px;
				color: $black-2;
				.last-element {
					display: flex;
					align-items: center;
					height: 100%;
					gap: 1rem;
					.link-last-element {
						display: flex;
						.see,
						.option {
							cursor: pointer;
							font-size: 1.2rem;
							color: $black-6;
						}
					}
				}
				.object {
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					width: 60%;
					height: 50%;
				}
				.status {
					border-radius: 36px;
					width: 5rem;
					color: $green-3-1;
					background-color: $green-3-0;
					text-align: center;
					padding: .3rem .0rem;
					font-weight: 500;
					letter-spacing: .5px;
					&.activé {
						color: $danger;
						background-color: $orange-3;
					}
				}
				.contain {
					position: relative;
					@include flexcenter;
				}
				td{
					font-size: 14px;
				}
			}
		}
	}
}
