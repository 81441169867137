// -----------------------------------------------------------------------------
// This file contains all application-wide Sass mixins.
// -----------------------------------------------------------------------------
@use "./variables" as *;

@mixin flexcenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin activeStatus {
  border-radius: 36px;
  color: $danger;
  background-color: $orange-3;
  text-align: center;
  padding: 0.2rem 0rem;
  font-weight: 500;
  letter-spacing: 1px;
  &.activé {
    color: $green-3-1;
    background-color: $green-3-0;
  }
}

@mixin addInfos {
  background-color: $white;
  width: 37rem;
  padding: 1rem;
  box-shadow: $box-shadow;
  border: $primary-border;
  border-radius: $border-radius;
  padding-bottom: 1.3rem;
  margin-bottom: 1.5rem;
}

@mixin header-page-add-see {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  width: 100%;
  :not(button):nth-child(1) {
    color: $black-6;
  }

  h1 {
    font-size: 1.7rem;
  }
  .deluser{
    border: 1px solid $gray-3;
    border-radius: 50%;
    @include flexcenter;
    width: 2.5rem;
    height: 2.5rem;
    cursor: pointer;
  }
}
