@use '../../../sass' as *;

#updatePassword {
  width: 100%;
  @media (max-width: 1300px) {
    width: 69rem;
  }
  .navbar {
    .right {
      .right-items {
        .button-nature {
          border: none;
        }
      }
    }
  }
  .submit-btns {
    display: flex;
    gap: 2rem;
  }
  #box {
    width: 80%;
    form {
      max-width: 60%;
      .label-input {
        padding-block: 0.7rem;

        label {
          margin-bottom: 0.5rem;
        }
      }
    }
  }
}
