@use '../../../sass/' as *;

#catagory-cp{
    width: 100%;
	@media (max-width: 1300px) {
		width: 69rem;
	}
	h1 {
		font-size: 1.7rem;
	  }
	.mainContent {
		.titres {
			display: grid;
			grid-template-columns: 20% 80%;
			height: 2.5rem;
			align-items: center;
			font-weight: 600;
			td {
			  font-size: 14px;
			  text-align: left;
			  letter-spacing: 1px;
			}
		}
		tbody {
			.element {
				display: grid;
				grid-template-columns: 20% 80%;
				height: 5rem;
				#last{
					width: 80%;
					display: -webkit-box;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
					overflow: hidden;
					&:hover{
						-webkit-line-clamp: unset;
					}
				}
			}
		}
	}
}