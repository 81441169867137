@use "../../../sass/" as *;

#productPage {
  width: 100%;
  @media (max-width: 1300px) {
    width: 69rem;
  }
  #head {
    padding-block: 1.5rem;
    @include header-page-add-see;
  }
  .modalcontainer {
    .modal {
      flex-direction: column;
      height: 24rem;
      top: 30%;
      width: 30rem;
      padding: 2rem;
      h1 {
        font-size: 1.4rem;
        text-transform: uppercase;
        text-align: center;
      }
      form {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        .line-updateStock {
          display: flex;
          align-items: center;
          gap: 2rem;
          h2 {
            font-size: 1.1rem;
            width: 30%;
            color: $black-7;
          }
          span {
            width: 60%;
            color: #059669;
          }
          #btn-ustock {
            display: flex;
            gap: 0.7rem;
            #state-on {
              width: 8rem;
              height: 2rem;
              color: $green-1;
              border-radius: 20px;
              background-color: #bbdddd;
            }
            #state-off {
              width: 7rem;
              height: 2rem;
              color: $green-1;
              border-radius: 20px;
              background-color: transparent;
              border: 1px solid $gray-3;
            }
          }
          input {
            width: 30%;
          }
        }
        .modalbtn {
          margin-top: 2rem;
          display: flex;
          justify-content: center;
        }
      }
    }
  }
  #productsContent {
    width: 100%;
    #products {
      width: 100%;
      border-top: 1px solid $gray-1-0;
      border-bottom: 1px solid $gray-1-0;
      #titres {
        width: 100%;
        display: grid;
        grid-template-columns: 5% 15% 20% 18% 15% 17% 10%;
        height: 2.5rem;
        align-items: center;
        font-weight: 600;
        td {
          font-size: 14px;
          text-align: left;
          letter-spacing: 1px;
          &.center {
            margin: auto;
          }
        }
      }
      tbody {
        width: 100%;
        .product {
          height: 3rem;
          border-top: 1px solid $gray-1-0;
          display: grid;
          align-items: center;
          grid-template-columns: 5% 15% 20% 18% 15% 17% 10%;
          letter-spacing: 0.5px;
          font-size: $font-size - 0.3rem;
          .last-user-element {
            display: flex;
            align-items: center;
            height: 100%;
            gap: 1rem;
            .link-user {
              display: flex;
            }
            .see-user,
            .user-option {
              cursor: pointer;
              font-size: 1.2rem;
              color: $black-0;
            }
          }
          td:nth-child(-n + 4) { 
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 200px;
            transition: 0.1s all ease-in-out;
          }
          .center {
            margin: auto;
          }
          .product-stock {
            width: 7rem;
            @include activeStatus;
          }
          .product-status {
            width: 6rem;
            @include activeStatus;
          }
          .contain {
            display: inline-block;
            position: relative;
            @include flexcenter;
          }
        }
      }
    }
  }
}
