@use '../../../sass/' as *;

#complaintsPage {
  width: 100%;
  @media (max-width: 1300px) {
    width: 69rem;
  }
  .title-user h1 {
    font-size: 1.7rem;
  }
  #complaintsContent {
    width: 100%;
    @media (max-width: 1300px) {
      width: 68rem;
    }

    #complaints {
      width: 100%;
      border-top: $primary-border;
      border-bottom: $primary-border;
      #titres {
        display: grid;
        grid-template-columns: 5% 18% 15% 19% 19% 14% 10%;
        height: 3rem;
        align-content: center;
        align-items: center;
        td {
          font-size: 14px;
          font-weight: 600;
          letter-spacing: 0.5px;
          &.center {
            margin: auto;
          }
        }
      }
      tbody {
        width: 100%;
        .center {
          margin: auto;
        }
        .complain {
          width: 100%;
          height: 3.5rem;
          display: grid;
          grid-template-columns: 5% 18% 15% 19% 19% 14% 10%;
          align-items: center;
          align-content: center;
          border-top: $primary-border;
          letter-spacing: 0.5px;
          font-size: 0.9rem;
          #object {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            line-height: 1em;
            width: 60%;
            height: 50%;
          }
          .last-complain-element {
            display: flex;
            align-items: center;
            height: 100%;
            gap: 1rem;
            .link-complain {
              display: flex;
              justify-content: center;
            }
            .see-complain,
            .del-compl {
              cursor: pointer;
              font-size: 1.1rem;
              color: $black-0;
            }
          }
          td:nth-child(-n + 4) { 
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 250px;
            transition: 0.1s all ease-in-out;
          }
          .status {
            border-radius: 36px;
            width: 5rem;
            text-align: center;
            padding: 0.3rem 0rem;
            font-weight: 500;
            letter-spacing: 0.5px;
            color: $orange-1;
            background-color: $orange-2;
            &.traité {
              color: $green-3-1;
              background-color: $green-3-0;
            }
          }
        }
      }
    }
  }
}
