@use "../utilities/" as *;
html {
	background-color: $white-1;
}
* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	outline: none;
	border: none;
	font-family: "Mukta", sans-serif;
	line-height: normal;
	// overflow: visible;
}
.logo {
	width: 177px;
    height: 37px;
}
