@use '../utilities/' as *;

#notfound{
    @include flexcenter;
    width: 100%;
    .img-notfound{
        margin: 10% auto;
        display: flex;
        flex-direction: column;
        gap: 5%;
        h1{
            color: $green-1;
            font-size: 2rem;
        }
    }
}