@use '../../../sass/' as *;

#admin-complaints{
    width: 100%;
    @media (max-width: 1300px) {
		width: 65rem;
	}
    .mainContent{
        .titres {
            height: 3rem;
			display: grid;
			justify-content: center;
			grid-template-columns: 15% 15% 17% 15% 13% 10% 10%;
           
		}
		tbody {
			.element {
				display: grid;
				justify-content: center;
				grid-template-columns: 15% 15% 17% 15% 13% 10% 10%;
				.last-element {
                    width: 100%;
                    justify-content: center;
					.contain {
						.option {
							cursor: pointer;
						}
					}
				}
				td:nth-child(-n + 3) {
					display: -webkit-box;
					-webkit-line-clamp: 1;
					-webkit-box-orient: vertical;
					overflow: hidden;
					max-width: 200px;
				}	
                .status{
                    width: 6rem;
                    &.activé{
                        color: $orange-1;
                        background-color: $orange-2;
                    }
                }
			}
		}
    }
}