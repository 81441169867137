@use '../utilities/' as *;

.modalcontainer {
  box-shadow: none;
  position: absolute;
  .modal {
    background-color: $white;
    position: absolute;
    background-color: $white;
    border-radius: 4px;
    border: 1px solid $gray-1-1;
    display: none;
    left: 50%;
    transform: translate(-50%, -50%);
    h1 {
      font-size: 1.7rem;
      color: $black-6;
    }
    h3 {
      color: $black-7;
    }
    .modalbtn {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      gap: 1rem;
    }
    .desc-modal {
      display: flex;
      flex-direction: column;
    }
    &.active {
      display: flex;
    }
  }
  &.open {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1000;
    @include flexcenter;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.75);
  }
}
.modalSelectOpt {
  box-shadow: 0px 9px 18px 1px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  background-color: $white;
  position: absolute;
  top: 1rem;
  right: 0.5rem;
  width: max-content;
  z-index: 5;
  ul {
    display: flex;
    flex-direction: column;
    li {
      padding: 0.7rem 1.3rem;
      cursor: pointer;
      color: $gray-3;
      font-size: 0.9rem;
      font-family: "Inter", sans-serif;
      &:hover {
        color: $black-6;
      }
    }
    :nth-child(2),
    :nth-child(3),
    :nth-child(4) {
      border-top: $primary-border;
    }
  }
}

.modalCSV {
  box-shadow: none;
  .modal {
    background-color: $white;
    position: absolute;
    background-color: $white;
    border-radius: 4px;
    border: 1px solid $gray-1-1;
    display: none;
    height: 15rem;
    width: 20rem;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 1.5rem;
    flex-direction: column;
    h1 {
      font-size: 1.1rem;
      font-weight: 500;
      color: $black-6;
    }
    .modalbtn {
      display: flex;
      justify-content: center;
      width: 100%;
      gap: 24px;
      button {
        font-size: 1.1rem;
      }
    }
    form {
      height: 60%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 0.5rem;
      .line {
        label {
          margin-left: 0.5rem;
        }
      }
    }
    &.active {
      display: flex;
    }
  }
  &.open {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1000;
    @include flexcenter;
    background-color: rgba(0, 0, 0, 0.75);
  }
}
