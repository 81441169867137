@use '../../../sass/' as *;

#orderDetails {
	width: 100%;
	@media (max-width:1300px) {
		width: 63rem;
	}
	.details-order-header {
		@include header-page-add-see;
	}
	.container {
		margin-top: 2rem;
		.infos-societe {
			display: flex;
			flex-direction: column;
			gap: .5rem;
			label {
				display: inline-block;
				font-size: 16px;
				letter-spacing: 1px;
				color: $black-6;
				font-weight: 600;
			}
		}
		.credentials {
			margin-top: 2rem;
			display: flex;
			gap: 3rem;
			width: 100%;
			.left {
				display: flex;
				flex-direction: column;
				border: $primary-border;
				border-radius: $border-radius;
				padding: 1rem;
				width: 40%;
				.left-el {
					display: flex;
					flex-direction: column;
					margin-bottom: .5rem;
					label {
						color: $green-1;
						display: inline-block;
						font-size: 18px;
						font-weight: 600;
						letter-spacing: 1px;
					}
					span {
						color: $gray-3;
					}
				}
			}
		}
		.devis {
			p {
				color: $black-6;
				margin-top: 3rem;
				font-size: 16px;
				font-weight: 600;
				letter-spacing: .5px;
			}
			.devis-head {
				margin-top: 2rem;
				h1 {
					color: $black-0;
					border-bottom: 4px solid $green-1;
				}
			}
			.devis-table {
				width: 100%;
				margin-top: 6rem;
				.offres {
					width: 100%;
					border-top: 1px solid $gray-1-0;
					border-bottom: 1px solid $gray-1-0;

					#titres {
						width: 100%;
						height: 2.5rem;
						align-items: center;
						font-weight: 600;
						display: grid;
						grid-template-columns: 15% 20% 30% 10% 12% 13%;
						letter-spacing: .5px;
					}
					tbody {
						.elements {
							width: 100%;
							height: 3.5rem;
							align-items: center;
							display: grid;
							grid-template-columns: 15% 20% 30% 10% 12% 13%;
							letter-spacing: .5px;
							border-top: 1px solid $gray-1-0;
                            td{
                                color: $black-2;
                            }
						}
					}
				}
				.total-devis {
                    width: 100%;
					display: flex;
					justify-content: flex-end;
					margin-top: 4rem;
					margin-left: 10%;
					#box {
                        width: 40%;
						gap: 1rem;
						display: flex;
						align-items: center;
                        flex-direction: column;
                        justify-content: center;
                        div{
                            width: 100%;
                            display: grid;
                            grid-template-columns: 40% 60%;
                            gap: 1rem;
                            h1,
                            span {
                                font-size: 1.2rem;
                                color: $black-2;
                                font-weight: 600;
                                text-align: left;
                            }
                        }

					}
				}
			}
			.message {
				margin-top: 5rem;
				border-bottom: 1px solid $gray-1-0;
				padding-bottom: 2rem;
			}
			.footer {
				display: flex;
				gap: 4rem;
				margin-top: 3rem;
				padding-bottom: 3rem;
				.left{
					ul{
						display: flex;
						flex-direction: column;
						li {
							display: inline-block;
							font-size: 16px;
							letter-spacing: 1px;
							color: $black-6;
							font-weight: 600;
						}
					}
				}
			}
		}
	}
}
