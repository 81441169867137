@use '../../../sass' as *;

#comProfile-cp {
	width: 100%;
	@media (max-width: 1300px) {
		width: 69rem;
	}
    padding-bottom: 3rem;
	.navbar {
		.right{
			.right-items{
				.button-nature{
					border: none;
				}
			}

		}
	}
	.cp-box {
		margin-top: 1.5rem;
		width: 60%;
		letter-spacing: .5px;
		h2 {
			color: $black-6;
			font-size: 1.3rem;
		}
		.line {
			display: grid;
			grid-template-columns: 35% 65%;
			align-items: center;
			margin-top: .5rem;

			span,
			h3 {
				font-size: 1rem;
				color: $gray-4;
                padding: 0.3rem;
			}
			h3 {
				text-transform: capitalize;

			}
		}
	}
}
