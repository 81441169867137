@use "../../../sass/" as *;

#product-details {
    width: 100%;
    @media (max-width: 1300px) {
        width: 69rem;
    }
    .details-product-header {
        @include header-page-add-see;
    }
    .container {
        width: 100%;

        .pr-admin {
            display: flex;
            width: 100%;
            gap: 10%;
            margin-top: 5rem;
            .field-image-uploaded {
                @extend .field;
                width: 35rem;
                margin-top: 1rem;
                .imgBlock {
                    border: 3px dashed $gray-1;
                    border-radius: $border-radius;
                    display: block;
                    #imgUpload {
                        height: 25rem;
                        overflow: hidden;
                        position: relative;

                        .fileUp {
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            top: 0;
                            left: 0;
                            background-color: $white;
                            cursor: pointer;
                            img {
                                width: 100%;
                                height: 100%;
                                display: block;
                                object-fit: contain;
                            }
                        }
                    }
                }
            }
            #info-prod-right {
                width: 50%;
                h2 {
                    letter-spacing: 2px;
                    text-transform: uppercase;
                }
                p {
                    color: $gray-3;
                    margin-top: 2rem;
                }
            }
            .table {
                width: 50%;
                table {
                    width: 80%;
                    tr {
                        width: 100%;
                        display: grid;
                        grid-template-columns: repeat(2, 50%);
                    }
                    .indication {
                        color: $black-5;
                    }
                    td {
                        padding: 1rem;
                        color: $gray-3;
                        text-transform: capitalize;
                    }
                }
                table,
                td {
                    border: 1px solid $gray-2;
                    border-collapse: collapse;
                }
            }
        }
    }
}
