@use '../../../sass/' as *;

#user-details {
	width: 100%;
	@media (max-width:1300px) {
			width: 69rem;
		}

	.details-user-header {
		@include header-page-add-see;
	}
	.container {
		margin-top: 2rem;
		width: 70%;
		h3{
			color: $black-6;
		}
		.user-type {
            margin-bottom: 2rem;
			.line {
                margin-top: 1rem;
                width: 35rem;
                display: flex;
				flex-wrap: nowrap;
				justify-content: space-between;
                align-items: center;
                label{
					color: $gray-3;
					font-weight: 600;
					display: inline-block;
				}
				span {
					display: inline;
					color: $gray-3;
					width: 60%;
				}
			}
		}
	}
}
